import React from 'react'
import bgImage from '../assets/contact/hero.png'
import bg from '../assets/GIThome.png'
import { BsTelephone } from "react-icons/bs";
import { MdDownload, MdOutlineMail } from "react-icons/md";
import { FiBook, FiUser } from "react-icons/fi";
import { FaRegComment } from "react-icons/fa6";

const Contact = () => {
    return (
        <div className='flex flex-col gap-[64px]'>

            <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
                style={{ backgroundImage: `url(${bgImage})` }}>
                <div className="absolute inset-0 bg-gradient-to-r from-[#171f81]/[.33] to-[#c5cfeff0]/[.18] z-0"></div>
                <div className='flex flex-col gap-[48px] p-28 z-10'>
                    <div className='flex flex-col w-[810px] '>
                        <p className='font-nunito text-[48px] leading-[67.2px] text-left'>
                            Contact us                        </p>
                    </div>
                </div>
            </div>

            <div className='flex p-20 justify-center items-center gap-[32px]'>
                <div className='items-center w-[50%] flex flex-col gap-[32px]'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7560.836290586163!2d73.764682!3d18.645223!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9c359982581%3A0x7981a7c5d2397867!2sEsparse%20Matrix%20Solutions%20Pvt%20Ltd%20-%20Innovative%20Tech%20Solutions%20for%20Your%20Business!5e0!3m2!1sen!2sin!4v1725633953476!5m2!1sen!2sin"
                        width="615"
                        height="375"
                        className="border-0"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        title="Location on Google Maps">
                    </iframe>
                    <button className='w-[223.39px] h-[48.98px] p-[10.25px_23.92px] gap-0 rounded-lg border border-solid border-[#2F55D4] shadow-[0px_3.42px_5.7px_0px_rgba(47,85,212,0.3)] bg-[#2F55D4] text-[white] font-medium flex items-center justify-center gap-3'>
                        Company Profile <MdDownload />
                    </button>

                </div>

                <div className=' w-[50%]'>




                    <form className='w-[615px] h-[680px] p-[54px_34px_36px_34px] flex flex-col gap-[32px] rounded-[12px] border-2 bg-white'>

                        <div className='flex gap-[32px] items-center justify-center'>

                            <div className='w-[50%] h-[73px]'>
                                <label className='text-[14px] font-bold leading-[21px] text-left'>
                                    Your Name <span className='text-[#E43F52]'>*</span>
                                </label>
                                <div className='relative w-full my-3'>
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                        <FiUser className='text-xl' />
                                    </span>
                                    <input
                                        type='text'
                                        name='your name'
                                        placeholder='Your Name:'
                                        className="border-2 w-full border-2 border-[#DEE2E6] py-2 pl-10 pr-4 rounded-lg focus:outline-none-lg focus:ring-2-lg focus:ring-blue-500"
                                    />
                                </div>
                            </div>

                            <div className='w-[50%] h-[73px]'>
                                <label className='text-[14px] font-bold leading-[21px] text-left'>
                                    Your Name <span className='text-[#E43F52]'>*</span>
                                </label>
                                <div className='relative w-full my-3'>
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                        <BsTelephone className='text-lg' />
                                    </span>
                                    <input
                                        type='tel'
                                        name='your mobile'
                                        placeholder='Your Mobile:'
                                        className="border-2 w-full border-2 border-[#DEE2E6] py-2 pl-10 pr-4 rounded-lg focus:outline-none-lg focus:ring-2-lg focus:ring-blue-500"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='w-[100%] h-auto flex flex-col gap-[22px]'>
                            <div className='w-full h-[73px]'>
                                <label className='text-[14px] font-bold leading-[21px] text-left'>
                                    Your Email <span className='text-[#E43F52]'>*</span>
                                </label>
                                <div className='relative w-full my-3'>
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                        <MdOutlineMail className='text-xl' />
                                    </span>
                                    <input
                                        type='text'
                                        name='your email'
                                        placeholder='Your Email:'
                                        className="border-2 w-full border-2 border-[#DEE2E6] py-2 pl-10 pr-4 rounded-lg focus:outline-none-lg focus:ring-2-lg focus:ring-blue-500"
                                    />
                                </div>
                            </div>


                            <div className='w-full h-[73px]'>
                                <label className='text-[14px] font-bold leading-[21px] text-left'>
                                    Subject
                                </label>
                                <div className='relative w-full my-3'>
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                        <FiBook className='text-lg' />
                                    </span>
                                    <input
                                        type='text'
                                        name='subject'
                                        placeholder='Subject'
                                        className="border-2 w-full border-2 border-[#DEE2E6] py-2 pl-10 pr-4 rounded-lg focus:outline-none-lg focus:ring-2-lg focus:ring-blue-500"
                                    />
                                </div>
                            </div>



                            <div className='w-full h-[73px]'>
                                <label className='text-[14px] font-bold leading-[21px] text-left'>
                                    Comments
                                </label>
                                <div className='relative w-full my-3'>
                                    <span className="absolute inset-y-0 left-0 top-3 pl-3">
                                        <FaRegComment className='text-lg' />
                                    </span>
                                    <textarea
                                        type='text'
                                        rows='6'
                                        name='comment'
                                        className="border-2 w-full border-2 border-[#DEE2E6] py-2 pl-10 pr-4 rounded-lg focus:outline-none-lg focus:ring-2-lg focus:ring-blue-500"
                                    />
                                </div>
                                <div className='mt-5'>
                                    <button className='w-full h-[48.98px] p-[10.25px_23.92px] gap-0 rounded-lg border border-solid border-[#2F55D4] shadow-[0px_3.42px_5.7px_0px_rgba(47,85,212,0.3)] bg-[#2F55D4] text-[white] font-medium'>
                                        Send
                                    </button>
                                </div>
                            </div>




                        </div>



                    </form>
                </div>
            </div>
        </div >
    )
}

export default Contact