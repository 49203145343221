import React from 'react'
import p1 from '../assets/whatwedo.png'

const AboutSection4 = () => {
    return (
        <div className='h-auto flex flex-col gap-[36px] items-center relative px-24'>
            <p className='text-[32px] font-semibold leading-[23.4px] text-center'>
                What We Do
            </p>
            <div className='flex gap-[72px] items-center'>
                <div className='w-[554px]'>
                    <img src={p1} alt='what we do' />
                </div>

               

                <div className='w-[630px] font-[16px] leading-[25.6px] text-left'>
                    <p className='pb-5'>
                        We want to recognize as a leading provider of Digital Marketing and Software development services. We are committed to earning our clients trust through outstanding execution and uncompromising dedication to their success.
                    </p>
                    <p>
                        Our Mission is to achieve Client Business goal with Quality Software development services. We will be best in providing consistently successful, unique, and forward-thinking digital marketing that take into account the individual requirements and unique demands of each client.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AboutSection4