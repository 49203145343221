import React from 'react'
import bgImage from '../assets/career/hero2.png'
import { FiUserCheck, FiMonitor } from "react-icons/fi";
import { FaBuilding, FaClipboardList, FaGraduationCap, FaLaptopCode, FaRegListAlt } from 'react-icons/fa'

const SingleCareer = () => {
    return (
        <div className='flex flex-col gap-[64px]'>

            <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
                style={{ backgroundImage: `url(${bgImage})` }}>
                <div className="absolute inset-0 bg-gradient-to-r from-[rgba(47,58,181,0.2)] via-transparent to-[rgba(197,207,239,0.18)] z-0"></div>
                <div className='flex flex-col gap-[48px] p-28 z-10'>
                    <div className='flex flex-col w-[415px] h-[270px] gap-[32px]'>
                        <p className='font-nunito text-[48px] leading-[67.2px] text-left'>
                            Andriod Developer
                        </p>
                        <p className="text-[18px] w-[660px] font-normal leading-[25.6px] text-left">
                            We Esparse Matrix Solutions Team are working on Various Projects like Websites Design & Development, Mobile applications Developments, software Developments, ERP Development, and digital marketing services.                        </p>
                    </div>
                </div>
            </div>

            <div className='mb-20 px-38 flex gap-16 justify-center'>

                <div className='w-[386.75px] h-fit pb-10 rounded-lg shadow-[0px_0px_4px_2px_#3C485826]'>

                    <div className='h-[87.3px] px-5 py-[5.97px] border-b-2  flex items-center'>
                        <p className='text-[22.1px] text-left font-semibold'>Job Information</p>
                    </div>
                    <div className='flex items-center mt-5 flex-col gap-5'>

                        <div className='flex items-center gap-4 w-[333.71px]'>
                            <FiUserCheck className='w-[22px] h-[22px]' />
                            <div>
                                <p className='text-[18px] font-semibold'>Employee Type:</p>
                                <p className='text-[16px] text-[#2F55D4]'>Internship</p>
                            </div>
                        </div>

                        <div className='flex items-center gap-4 w-[333.71px]'>
                            <FiMonitor className='w-[22px] h-[22px]' />
                            <div>
                                <p className='text-[18px] font-semibold'>Job Type:</p>
                                <p className='text-[16px] text-[#2F55D4]'>Andriod Developer
                                </p>
                            </div>
                        </div>

                        <div className='flex items-center gap-4 w-[333.71px]'>
                            <FaBuilding className='w-[22px] h-[22px]' />
                            <div>
                                <p className='text-[18px] font-semibold'>Company Name:</p>
                                <p className='text-[16px] text-[#2F55D4]'>eSparse Matrix Solutions Pvt Ltd</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='w-[780.13px] flex flex-col gap-5'>
                    <div className='flex items-center gap-4 w-[333.71px]'>
                        <FaClipboardList className='w-[22px] h-[22px]' />
                        <p className='text-[18px] font-semibold'> Job Description:</p>
                    </div>
                    <p>We Esparse Matrix Solutions Team are working on Various Projects like Websites Design & Development, Mobile applications Developments, software Developments, ERP Development, and digital marketing services.</p>
                    <div className='flex items-center gap-4 w-[333.71px]'>
                        <FaRegListAlt className='w-[22px] h-[22px]' />
                        <p className='text-[18px] font-semibold'>  Responsibilities and Duties:</p>
                    </div>
                    <ul>
                        <li>1. Support in the end to end delivery of app features by using the latest architecture, design patterns and writing secure and performant code</li>
                        <li>2. Developing, releasing, and maintaining Android Application and oversee mobile application</li>
                    </ul>
                    <div className='flex items-center gap-4 w-[333.71px]'>
                        <FaGraduationCap className='w-[22px] h-[22px]' />
                        <p className='text-[18px] font-semibold'> Required Qualifications:</p>
                    </div>
                    <p>Bachelor's degree in computer science or related field or equivalent education and experience.</p>
                    <div className='flex items-center gap-4 w-[333.71px]'>
                        <FaLaptopCode className='w-[22px] h-[22px]' />
                        <p className='text-[18px] font-semibold'> Skills:</p>
                    </div>
                    <div>
                        <li>Kotlin, and Java </li>
                    </div>

                    <button className='border-2 border-[#2F55D4] text-[#2F55D4] h-[47.52px] w-[165.75px] rounded-lg'>Apply Now</button>
                    

                </div>

            </div>

        </div>
    )
}

export default SingleCareer