import React from 'react';
import l1 from '../assets/icons/one-stop.png';
import l2 from '../assets/icons/continuous-image.png'
import l3 from '../assets/icons/strong-tech.png'
import l4 from '../assets/icons/priority-to-client.png'
import wcu1 from '../assets/homeWCU/image1.png'
import wcu2 from '../assets/homeWCU/image2.png'
import wcu3 from '../assets/homeWCU/image3.png'
import wcu4 from '../assets/homeWCU/image4.png'

const listItems = [
    {
        icon: l1,
        text: 'One-stop Solutions',

    },
    {
        icon: l2, // No icon for this item
        text: 'Continuous Improvement'
    },
    {
        icon: l3, // No icon for this item
        text: 'Strong Technical Expertise'
    },
    {
        icon: l4, // No icon for this item
        text: 'Priority to Client Satisfaction'
    }
];

const WhyChooseUs = () => {
    return (
        <div className='h-[608px] flex flex-col gap-[36px] items-center relative'>
            <p className='text-[32px] font-semibold leading-[23.4px] text-center'>
                Why to choose us
            </p>
            <div className='flex gap-[64px] w-full px-20'>
                <div className='flex flex-col justify-center gap-[36px] w-2/5'>
                    <p className='text-[28px] font-medium leading-[36px] text-left'>
                        "Why eSparse Matrix Solutions is Your Ideal Technology Partner"
                    </p>
                    <div className='flex flex-col gap-[28px]'>
                        {listItems.map((item, index) => (
                            <p key={index} className='flex text-[20px] font-semibold leading-[30px] text-left items-center gap-[44px] w-[540px] h-[64px]'>
                                {item.icon && (
                                    <div className='bg-[#1E90FF]  w-[64px] h-[64px] rounded-[79.48px] '>
                                        <img src={item.icon} alt={`icon-${index}`} className='object-contain p-4' />
                                    </div>
                                )}
                                <li>
                                    {item.text}
                                </li>
                            </p>
                        ))}
                    </div>
                </div>
                <div className='w-1/2'>
                    <div className="bg-[#EAEEFB] w-[728.83px] h-[399.64px] z-0 absolute bottom-0 right-4"></div>
                    <div className='w-[537.77px] h-[521.07px] z-10 absolute right-32'>
                        <img src={wcu1} alt='wcu' className='w-[178.52px] h-[254.53px]'/>
                        <img src={wcu2} alt='wcu' className='w-[334.8px] h-[223.35px] absolute bottom-8'/>
                        <img src={wcu3} alt='wcu' className='w-[347.25px] h-[231.5px] absolute top-0 right-0'/>
                        <img src={wcu4} alt='wcu' className='w-[178.53px] h-[254.53px] absolute bottom-0 right-0'/>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default WhyChooseUs;
