import React from 'react'
import bgImage from '../assets/aboutHero.png'

const AboutSection1 = () => {
    return (
        <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
            style={{ backgroundImage: `url(${bgImage})` }}>
            <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)]  z-0"></div>
            <div className='flex flex-col gap-[48px] p-28 z-10'>
                <div className='flex flex-col w-[790px] h-[270px] gap-[32px]'>
                    <p className='font-nunito text-[48px] leading-[67.2px] text-left'>
                        About Us
                    </p>
                    <p className="text-[18px] font-normal leading-[25.6px] text-left">
                        At eSparse Matrix Solutions, We will provide a one-stopSoftware development services for all customer, we will take care of all your needs right from Strategic conceptSoftwaredevelopment, Business analysis, Software developmentservices to Digital Marketing all in one place. Once you join with eSparse Matrix Solutions, you will never have to go anywhere else again.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AboutSection1