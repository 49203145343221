import React from 'react'
import icon1 from '../assets/icons/aboutSec5a.png'
import icon2 from '../assets/icons/aboutSec5b.png'
import icon3 from '../assets/icons/aboutSec5c.png'
import icon4 from '../assets/icons/aboutSec5d.png'


const items = [
    {
        icon: icon1,
        title: 'Availability',
        desc: 'We are always ready to serve Software development and digital marketing services irrespective of time.'
    },
    {
        icon: icon2,
        title: 'Promises',
        desc: 'We deliver you a best Software development services in pune with assured quality at a reasonable price.'
    },
    {
        icon: icon3,
        title: 'Creativity',
        desc: 'Our Software Development Company focuses on growing and taking risks allows completing work with creativity.'
    },
    {
        icon: icon4,
        title: 'Determination',
        desc: 'You come with software development determination and go with full satisfaction.  '
    }
]


const AboutSection5 = () => {


    return (
        <div className='bg-[#EAEEFB] h-[540px] flex  items-center justify-center gap-[29px] relative'>
            <div className='flex flex-col gap-[32px]'>
                <p className='text-[32px] font-semibold leading-[23.4px] text-center'>
                    Value and believe
                </p>
                <div className='flex gap-[43px]'>
                    {items.map((item) => (

                        <div className='w-[276px] h-[296px] p-[24px_18px_24px_18px_] flex flex-col items-center justify-center bg-[white] gap-[8px] shadow-[1px_1px_8px_2px_#515A9340]'>

                            <div className='w-[237px] flex justify-center'>
                                <img src={item.icon} alt='icon1' className='w-[60.33px] h-[59.49px]' />

                            </div>
                            <p className='text-[#3C4858] font-[900] font-[20px]'>
                                {item.title}
                            </p>
                            <p className='font-[#161C2D] font-[16px] leading-[25.6px] text-center px-3'>
                                {item.desc}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AboutSection5