import React from 'react'
import bgImage from '../../assets/servicesImages/devops/hero.png'
import arrow from '../../assets/viewdetails.png'
import pic1 from '../../assets/servicesImages/devops/1.png'
import pic2 from '../../assets/servicesImages/devops/2.png'
import pic3 from '../../assets/servicesImages/devops/3.png'
import pic4 from '../../assets/servicesImages/devops/4.png'
import Slider from 'react-slick'
import icon1 from '../../assets/servicesImages/devops/icon1.png'
import icon2 from '../../assets/servicesImages/devops/icon2.png'
import icon3 from '../../assets/servicesImages/devops/icon3.png'
import a from '../../assets/servicesImages/devops/a.png'
import b from '../../assets/servicesImages/devops/b.png'
import c from '../../assets/servicesImages/devops/c.png'
import { MdKeyboardArrowDown } from "react-icons/md";


const item = [
    {
        icon: icon1,
        title: 'Discovery & Analysis',
        desc: 'The journey begins with a detailed discovery phase, where we dive into understanding your business objectives and requirements. This step involves a comprehensive assessment of your existing infrastructure, applications, and data to tailor a cloud strategy that aligns with your organizational goals.'
    },
    {
        icon: icon2,
        title: 'Feasibility & Planning',
        desc: 'The journey begins with a detailed discovery phase, where we dive into understanding your business objectives and requirements. This step involves a comprehensive assessment of your existing infrastructure, applications, and data to tailor a cloud strategy that aligns with your organizational goals.'
    },
    {
        icon: icon3,
        title: 'Architecture Design',
        desc: 'Our experienced engineers then design a scalable and secure cloud architecture, utilizing platforms like AWS, Azure, or Google Cloud. This phase focuses on crafting a robust infrastructure blueprint that integrates seamlessly with your business processes.'
    },
]

const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 4,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
};

const settings2 = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 3.5,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
};

const cards = [
    {
        image: pic1,
        tile: "Continuous Integration and Continuous Deployment (CI/CD)",
        point1: "Automate your build, test, and deployment pipelines.",
        point2: "Reduce manual errors and accelerate time-to-market.",
        point3: "Ensure high-quality code with automated testing and integration."
    },
    {
        image: pic2,
        tile: "Infrastructure as Code (IaC)",
        point1: "Manage and provision your infrastructure using code and automation.",
        point2: "Achieve consistency across environments and improve scalability.",
        point3: "Utilize tools such as Terraform, Ansible, and CloudFormation."
    },
    {
        image: pic3,
        tile: "Monitoring and Logging",
        point1: "Implement comprehensive monitoring solutions to gain real-time insights into your systems.",
        point2: "Use logging tools to troubleshoot and resolve issues quickly.",
        point3: "Ensure system reliability with proactive monitoring and alerting."
    },
    {
        image: pic4,
        tile: "Cloud Services and Migration",
        point1: "Optimize and manage your cloud infrastructure with best      practices.",
        point2: "Facilitate smooth migration to cloud platforms like AWS, Azure,      or Google Cloud.",
        point3: "Leverage cloud-native services to enhance scalability and flexibility."
    },
    {
        image: pic1,
        tile: "Continuous Integration and Continuous Deployment (CI/CD)",
        point1: "Automate your build, test, and deployment pipelines.",
        point2: "Reduce manual errors and accelerate time-to-market.",
        point3: "Ensure high-quality code with automated testing and integration."
    },
]
const DevOps = () => {
    return (
        <div className='flex flex-col gap-[64px]'>

            <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
                style={{ backgroundImage: `url(${bgImage})` }}>
                <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)]  z-0"></div>
                <div className='flex flex-col gap-[48px] p-28 z-10'>
                    <div className='flex flex-col  gap-[32px]'>
                        <p className='w-[660px] text-[48px] leading-[67.2px] text-left'>
                            DevOps Services                      </p>
                        <p className="text-[18px] w-[500px] font-normal leading-[25.6px] text-left">
                            Transform Your Development and Operations with Our Expert DevOps Solutions                                </p>
                    </div>
                </div>
            </div>


            <div className='flex flex-col gap-[36px] px-28'>
                <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Transform Your Development and Operations with Our Expert DevOps Solutions</p>
                <p className='text-[16px] leading-[25.6px] text-justify'>In today’s fast-paced digital landscape, efficiency, scalability, and reliability are key. Our DevOps services are designed to bridge the gap between development and operations, ensuring that your software delivery process is seamless, automated, and robust.</p>
            </div>

            {/* <div className='flex flex-col gap-[36px] '> */}
            <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>TOur DevOps Services Include:</p>

            <Slider {...settings} className="overflow-hidden w-full px-10">

                {cards.map((item) => (
                    <div>
                        <div className='w-[292px] h-[506px] rounded-lg shadow-[0px_4px_4px_0px_#00000040] gap-[16px]' >
                            <img src={item.image} alt='icon1' className='mb-3' />
                            <div className='flex flex-col  gap-[12px] px-2'>
                                <p className='text-[16px] px-5 font-semibold text-center'>
                                    {item.tile}
                                </p>
                                <div className='flex flex-col gap-[14px]'>
                                    <li className="text-[16px] font-normal text-left">
                                        {item.point1}
                                    </li>
                                    <li className="text-[16px] font-normal text-left">
                                        {item.point2}
                                    </li>
                                    <li className="text-[16px] font-normal text-left">
                                        {item.point3}
                                    </li>
                                </div>
                            </div>
                            <div className='flex text-[#0058AE] text-[12px] p-4 gap-1'>
                                <a href='/'>View Details</a>
                                <img src={arrow} alt='arrow' className='w-[9px]' />
                            </div>
                        </div>
                    </div>

                ))}
            </Slider>

            <div className='flex flex-col gap-[36px] px-28'>
                <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>
                    Our Cloud Engineering Journey
                </p>
                <p className='text-[16px] leading-[25.6px] text-justify'>
                    Adopt Esparse Matrix Solutionsmeticulous Cloud      Engineering Process, thoughtfully crafted for a smooth and efficient cloud      transformation. Our approach combines technical excellence with tailored      strategic planning to precisely align with your business's unique needs      and objectives. Each stage seamlessly builds upon the previous, ensuring a      comprehensive and successful transition to the cloud.
                </p>
            </div>


            <Slider {...settings2} className="overflow-hidden w-full px-5">
                {item.map((item) => (
                    <div>

                        <div className='w-[362px] h-[441px] p-[24px_18px_24px_18px_] flex flex-col items-center justify-center bg-[white] gap-[8px] border-2 rounded-lg'>

                            <div className='w-[237px] flex justify-center'>
                                <img src={item.icon} alt='icon1' className='w-[60.33px] h-[59.49px]' />

                            </div>
                            <p className='text-[#3C4858] font-[900] font-[20px]'>
                                {item.title}
                            </p>
                            <p className='font-[#161C2D] font-[16px] leading-[25.6px] text-center px-3'>
                                {item.desc}
                            </p>
                        </div>
                    </div>
                ))}
            </Slider>

            <div className='flex flex-col gap-[36px] px-28'>
                <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Our Cloud Expertise Advantage</p>
                <p className='text-[16px] leading-[25.6px] text-justify'>Esparse Matrix Solution sexpertise in Cloud Engineering services is designed to enable organizations to fully harness the benefits of cloud technology. Here’s an overview of the advantages organizations can expect by partnering with Tech Active.</p>
            </div>


            <div className='bg-[#EAEEFB] h-[540px] flex  items-center justify-center gap-[29px] relative'>
                <div className='flex flex-col gap-10 items-center'>
                    <div className='h-[281px] flex gap-20 justify-center'>
                        <div className='flex flex-col gap-[28px] w-[329px] items-center'>
                            <img src={a} alt='a' className='w-[64px] h-[62px]'/>
                            <p className='text-[24px] font-semibold'>Speed and Agility</p>
                            <p className='text-[16px] font-normal'>Esparse Matrix Solutionsexpertise in cloud engineering accelerates the deployment and scaling process, enabling businesses to rapidly adapt to market changes and emerging opportunities.</p>
                        </div>
                        <div className='flex flex-col gap-[28px] w-[329px] items-center'>
                            <img src={b} alt='a' className='w-[94px] h-[62px]'/>
                            <p className='text-[24px] font-semibold'>Reliability and Performance</p>
                            <p className='text-[16px] font-normal'>We deploy cloud environments where continuous integration and delivery (CI/CD) are at the core, leading to high-quality application updates and stable infrastructure changes.</p>
                        </div>
                        <div className='flex flex-col gap-[28px] w-[329px] items-center'>
                            <img src={c} alt='a' className='w-[64px] h-[62px]'/>
                            <p className='text-[24px] font-semibold'>Cost-Efficiency</p>
                            <p className='text-[16px] font-normal'>Our cloud engineering practices are designed to optimize resource utilization, which can significantly reduce overhead costs associated with traditional IT infrastructure.</p>
                        </div>
                    </div>
                    <button className='flex items-center bg-[#1F83F2] w-[117.17px] h-[30px] rounded-full text-white justify-center'>show more <MdKeyboardArrowDown className='text-[24px]' /></button>
                </div>




            </div>

        </div>
    )
}

export default DevOps