import React from 'react'
import logo from '../assets/logo.png'
import fb from '../assets/icons/fb.png'
import ig from '../assets/icons/ig.png'
import x from '../assets/icons/x.png'
import ln from '../assets/icons/in.png'
import yt from '../assets/icons/yt.png'
import { Link } from 'react-router-dom'
import { FaLocationDot } from "react-icons/fa6";
import { MdMail } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";



const Footer = () => {


    return (
        <div className="bg-[#0058AE] h-[354.4px]  p-[40px_88px_36px_88px] flex justify-between">

            <div className='flex flex-col gap-[16px] w-[380px]'>
                <div className='flex justify-center'>
                    <img src={logo} alt='logo' className='w-[60] h-[50px]' />
                </div>
                <p className='text-[20px] font-black text-[white] leading-[30px] text-left'>
                    eSparse Matrix Solutions Pvt Ltd
                </p>
                <p className=' text-[16px] text-[white] leading-[25.6px] text-left pr-5'>
                    Esparse Matrix Solution Private Limited is a software development company based in India that serves clients all over the world. We concentrated on providing innovative resources to help clients expand.
                </p>
                <div className='flex gap-[4.3px] justify-start'>
                    <a href="https://www.facebook.com/esparsematrixsolutions/" target="_blank" rel="noreferrer">

                        <img src={fb} alt='fb' className='w-[32px] h-[32px]' />
                    </a>
                    <a href="https://www.instagram.com/esparse_matrix_solutions/" target="_blank" rel="noreferrer">

                        <img src={ig} alt='ig' className='w-[32px] h-[32px]' />
                    </a>
                    <a href="https://x.com/MatrixSparse" target="_blank" rel="noreferrer">

                        <img src={x} alt='x' className='w-[32px] h-[32px]' />
                    </a>
                    <a href="https://www.linkedin.com/company/sparse-matrix-solutions/" target="_blank" rel="noreferrer">

                        <img src={ln} alt='ln' className='w-[32px] h-[32px]' />
                    </a>
                    <a href="https://www.youtube.com/channel/UCmmkB3zOe1d7g6FvXqtGcQw" target="_blank" rel="noreferrer">

                        <img src={yt} alt='yt' className='w-[32px] h-[32px]' />
                    </a>
                </div>

            </div>

            <div className=' h-[314px] p-[15px] text-[white]' >
                <p className='text-[20px] leading-[30px] tracking-[1px] text-left '>
                    ABOUT
                </p>

                <ul className='py-3 flex flex-col gap-3'>
                    <Link to='/' >
                        <li> Home </li>
                    </Link>
                    <Link to='/aboutus'>
                        <li> About us</li>
                    </Link>
                    {/* <Link to='/services'>
                        <li>  Services</li>
                    </Link> */}
                    <Link to='/career'>
                        <li> Career</li>
                    </Link>
                    <Link to='/blog'>
                        <li>  Blog</li>
                    </Link>
                    {/* <Link to='/events'>
                        <li>  Events</li>
                    </Link> */}
                    <Link to='/contactus' >
                        <li>  Contact us</li>
                    </Link>
                </ul>
            </div>


            <div className=' h-[314px] p-[15px] text-[white]' >
                <p className='text-[20px] leading-[30px] tracking-[1px] text-left '>
                    POLICY
                </p>

                <ul className='py-3 flex flex-col gap-3'>

                    <li> Terms of Services</li>

                    <li> Privacy policy</li>

                    <li> Disclaimer</li>

                    <li> Sitemap</li>

                </ul>
            </div>


            <div className='h-[314px] p-[15px] text-[white]' >
                <p className='text-[20px] leading-[30px] tracking-[1px] text-left '>
                    OFFICE ADDRESS
                </p>

                <ul className='py-3 flex flex-col gap-3'>

                    <div className=''>
                        <p className='flex items-center gap-1'> <span><FaLocationDot /></span> 133/1, Spectrum Building,</p>
                        <p>Gurudwara Colony, Akurdi,
                            Pune-411033</p>
                    </div>

                    <div>
                        <p className='flex items-center gap-1'> <span><BsFillTelephoneFill /></span>   (+91) 8459694413</p>
                    </div>

                    <div>
                        <a href="mailto:info@sparsematrix.co.in" target="_blank" rel="noreferrer">
                            <p className='flex items-center gap-1'> <span><MdMail /></span>Click here to Email us</p>
                        </a>
                    </div>




                </ul>
            </div>

        </div>
    )
}

export default Footer