import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from '../assets/logo.png'
import searchIcon from '../assets/searchicon.png'
import ServicesNavbar from './ServicesNavbar'
import ProductsNavbar from './ProductsNavbar'

const Navbar = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);
    const [productsDropdownOpen, setProductsDropdownOpen] = useState(false);

    const toggleServicesMenu = (event) => {
        event.preventDefault();
        setServicesDropdownOpen(!servicesDropdownOpen);
        setProductsDropdownOpen(false);

    };

    const toggleProductsMenu = (event) => {
        event.preventDefault();
        setProductsDropdownOpen(!productsDropdownOpen);
        setServicesDropdownOpen(false);
 
    };

    const closeMenu = () => {
        setServicesDropdownOpen(false);
        setProductsDropdownOpen(false);

    };

    return (
        <div className="w-full fixed bg-[#FFFFFF] flex justify-between z-50 shadow-[0px_4px_4px_0px_#00000040] ">
            <div className='w-1/6 px-8 py-4 flex justify-end'>
                <img alt='logo' src={logo} className='w-[55.89px] h-[52.81px]' />
            </div>

            <ul className='flex w-2/4 p-4 justify-center items-center gap-8'>
                <Link onClick={closeMenu} to='/' className={`pt-4 pb-1 ${currentPath === '/' ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'} hover:text-[#2F55D4] transition duration-200`}>
                    Home
                </Link>
                <Link onClick={closeMenu} to='/aboutus' className={`pt-4 pb-1 ${currentPath === '/aboutus' ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'} hover:text-[#2F55D4] transition duration-200`}>
                    About us
                </Link>

                <li
                    className={`h-full pt-5 cursor-pointer ${currentPath.includes('/services') ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'} hover:text-[#2F55D4] transition duration-5000`}
                    onClick={toggleServicesMenu}
                >
                    Services
                    {servicesDropdownOpen && (
                        <ServicesNavbar />
                    )}

                </li>


                <li
                    className={`pt-5 h-full  cursor-pointer ${currentPath.includes('/products') ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'} hover:text-[#2F55D4] transition duration-200`}
                    onClick={toggleProductsMenu}
                >
                    Products
                    {productsDropdownOpen && (
                        <ProductsNavbar />
                    )}

                </li>

                <Link onClick={closeMenu} to='/career' className={`pt-4 pb-1 ${currentPath === '/career' ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'} hover:text-[#2F55D4] transition duration-200`}>
                    Career
                </Link>
                <Link onClick={closeMenu} to='/blog' className={`pt-4 pb-1  ${currentPath === '/blog' ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'} hover:text-[#2F55D4] transition duration-200`}>
                    Blog
                </Link>
                <Link onClick={closeMenu} to='/events' className={`pt-4 pb-1 ${currentPath === '/events' ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'} hover:text-[#2F55D4] transition duration-200`}>
                    Events
                </Link>
                <Link onClick={closeMenu} to='/contactus' className={`pt-4 pb-1  ${currentPath === '/contactus' ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'} hover:text-[#2F55D4] transition duration-200`}>
                    Contact us
                </Link>
            </ul >

            <div className='w-1/6 flex items-center gap-3 pr-75'>
                <img alt='search icon' src={searchIcon} className='w-[24px] h-[24px]' />
                <button className='bg-[#006BD3] text-[#FFFFFF] px-8 py-2 rounded'>Login</button>
            </div>
        </div >
    )
}

export default Navbar
