import React from 'react'
import bgImage from '../../assets/servicesImages/erp/hero.png'
import icon1 from '../../assets/servicesImages/erp/1.png'
import icon2 from '../../assets/servicesImages/erp/2.png'
import icon3 from '../../assets/servicesImages/erp/3.png'
import icon4 from '../../assets/servicesImages/erp/4.png'
import icon5 from '../../assets/servicesImages/erp/5.png'
import icon6 from '../../assets/servicesImages/erp/6.png'
import icon7 from '../../assets/servicesImages/erp/7.png'
import icon8 from '../../assets/servicesImages/erp/8.png'
import icon9 from '../../assets/servicesImages/erp/9.png'
import icon10 from '../../assets/servicesImages/erp/10.png'
import icon11 from '../../assets/servicesImages/erp/11.png'
import icon12 from '../../assets/servicesImages/erp/12.png'
import icon13 from '../../assets/servicesImages/erp/13.png'
import icon14 from '../../assets/servicesImages/erp/14.png'
import icon15 from '../../assets/servicesImages/erp/15.png'
import icon16 from '../../assets/servicesImages/erp/16.png'
import Slider from 'react-slick'
import icona from '../../assets/servicesImages/erp/a.png'
import iconb from '../../assets/servicesImages/erp/b.png'
import iconc from '../../assets/servicesImages/erp/c.png'
import icond from '../../assets/servicesImages/erp/d.png'
import icone from '../../assets/servicesImages/erp/e.png'
import iconf from '../../assets/servicesImages/erp/f.png'



const items = [
    {
        icon: icona,
        title: 'BEST UI Design',
        desc: 'One of our main goals was to streamline the user experience and simply make "things really work." The software is moved out of the way by the simple UI and quick navigation, allowing you the simple UI and quick navigation, allowing you to concentrate on your job.     '
    },
    {
        icon: iconb,
        title: 'Customizable and flexible',
        desc: "Sparse School/College ERP modules are adaptable and customizable to your institution's specific needs, making it a dynamic solution. "
    },
    {
        icon: iconc,
        title: 'Customizable and flexible',
        desc: "Sparse School/College ERP modules are adaptable and customizable to your institution's specific needs, making it a dynamic solution. "
    },
    {
        icon: icond,
        title: 'Zero Maintenance Charges',
        desc: "Our team manages and maintains Sparse School ERP, so you don't have to worry about protection or software upgrades, which are released on a regular basis. "
    },
    {
        icon: icone,
        title: 'Easily Accessible',
        desc: "Sparse School/College ERP is web-based, making it easy to access at any time. It's available on both mobile and desktop."
    },
    {
        icon: iconf,
        title: 'Fast and Easy Implementation',
        desc: 'Implementing Sparse School/College ERP is quick and easy thanks to its parameter-driven, rule-based architecture, making it one of the fastest ERP implementations available.'
    }
]


const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 3.5,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
};

const card = [
    {
        icon: icon1,
        title: 'Admission Management',
        desc: 'Streamline Admissions: Effortlessly convert inquiries into confirmations with direct student management. Easily configurable for seamless online admissions.'
    },
    {
        icon: icon2,
        title: 'Student Management',
        desc: 'Comprehensive Student Profiles: Include photos, family/guardian details, document uploads, bulk data import, auto roll number generation, and built-in ID card features'
    },
    {
        icon: icon3,
        title: 'Attendance Management',
        desc: 'Subject-wise Attendance: Track day-by-day attendance with daily, monthly, and annual reports. Receive absentee SMS alerts and monitor percentage attendance '
    },
    {
        icon: icon4,
        title: 'Fees Management',
        desc: 'Advanced Fee Management Customize fees by category, handle deposits, apply student-specific concessions, auto-generate receipts, send payment SMS alerts, and map with accounts.'
    },
    {
        icon: icon5,
        title: 'Timetable Management',
        desc: "Alerts for Overlapping timetable Student timetables Teacher timetables"
    },
    {
        icon: icon6,
        title: 'Academic',
        desc: 'Manage Classwork Efficiently: Update regular classwork, assign homework, organize lesson plans, schedule syllabuses, and track completed lessons.'
    },
    {
        icon: icon7,
        title: 'Event & Activity Management',
        desc: 'Dynamic School Calendar: Track events/activities, register easily, customize with user-defined colors, and integrate with mobile apps.'
    },
    {
        icon: icon8,
        title: 'Health Management Data',
        desc: 'Health Check-up Management: Utilize predefined or customized check-ups, report irregularities, manage health data, track student health records, and map vaccine history'
    },
    {
        icon: icon9,
        title: 'Student Assessment',
        desc: 'Support for All Boards: CBSE, ICSE, IB, and state boards. Integrated exam and grade converter, statistical and graphical data, and fully customizable mark-sheet generator with inbuilt designs.'
    },
    {
        icon: icon10,
        title: 'Human Resource',
        desc: 'Comprehensive Employee Manage -ment From recruitment to retirement, store basic, medical, work, and contact info, bulk upload data, generate employee ID cards, and access detailed reports.'
    },
    {
        icon: icon11,
        title: 'Employee Leave Management',
        desc: 'Leave Management System: Define leave types, set leave policies by grade, provide employee leave forms, streamline the approval process, generate leave reports, and track monthly attendance'
    },
    {
        icon: icon12,
        title: 'Payroll Management',
        desc: 'Payroll Management: Define department-specific payrolls, generate employee payroll slips, access annual and monthly reports, auto-estimate dependent fees, and manage PF and tax deductions.'
    },
    {
        icon: icon13,
        title: 'Reports',
        desc: 'Student Management Reports: Track student strength, attendance status, class strength, new admissions, and students on leave.'
    },
    {
        icon: icon14,
        title: 'Transport Management',
        desc: 'Transportation Management: Create routes with pick-up and drop-off points, handle flexible one-way transportation, integrate automatically with the fee module, and generate route-specific student reports.'
    },
    {
        icon: icon15,
        title: 'Library Management',
        desc: 'Transportation Management: Design routes with pick-up and drop-off points, manage flexible one-way transportation, seamlessly integrate with the fee module, and generate detailed reports for each route.'
    },
    {
        icon: icon16,
        title: 'Hostel Management',
        desc: 'Hostel Management System: Manage multiple hostels, identify room types and charges, quickly check room availability, integrate with the fee module, and generate comprehensive hostel reports.'
    },

]


const benefits = [
    {
        p1: 'Cloud Based ERP',
        p2: "Sparse School/College ERP is a cloud-based solution that provides cloud storage that can be accessed anytime, anywhere, and on any device, removing the need for schools to invest in and maintain expensive infrastructure and servers, reducing overall costs."
    },
    {
        p1: 'Real Time Data Availability',
        p2: "Parents and school officials will access real-time tracking data on students and school transportation. During pickup and drop, parents receive SMS updates with the time and place."
    },
    {
        p1: '24/7 Support Team',
        p2: "Our customer service team is always available to answer your questions and assist your organization."
    },
    {
        p1: 'Private and Secure Data',
        p2: "Sparse School/College ERP was created with our users' privacy and protection in mind, ensuring that all activities conducted through Sparse School/College ERP remain on Sparse School/College ERP. We never share your information with third parties, and our teams are constantly working to keep you and your information secure."
    },
]
const ERP = () => {
    return (
        <div className='flex flex-col gap-[64px]'>

            <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
                style={{ backgroundImage: `url(${bgImage})` }}>
                <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)]  z-0"></div>
                <div className='flex flex-col gap-[48px] p-28 z-10'>
                    <div className='flex flex-col w-[600px] h-[270px] gap-[32px]'>
                        <p className='font-nunito text-[48px] leading-[67.2px] text-left'>
                            All-In-One ERP for School and College
                        </p>
                        <p className="text-[18px] w-[500px] font-normal leading-[25.6px] text-left">
                            All reports on one click!                        </p>
                    </div>
                </div>
            </div>


            <div className='flex flex-col gap-[36px] px-52'>
                <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Why eSparse School ERP?</p>
                <p className='font-nunito text-[16px] font-medium leading-[25.6px] text-justify'>
                    A school ERP is a collection of computer instructions, specially designed to manage the day-to-day administrative tasks of schools. School management software allow schools to digitally monitor the daily activities along with managing all the resources and information on a single platform.
                    <br />
                    <br />
                    In contemporary, most of schools are using school managements software to increase efficiency, productivity, and hence saving a lot of time involved to carry out various administrative operations.
                </p>
            </div>


            <div className='flex flex-col gap-[36px] px-56'>
                <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Why ESPARSE HRMS?</p>
                <p className='font-nunito text-[16px] font-medium leading-[25.6px] text-center'>Esparse HRMS is useful because it allows for more operating hours a day. The presented personalised HRMS approach eliminates the system's flaws.
                    See how HRMS benefits our customers.
                </p>
            </div>

            <div className='flex flex-wrap justify-center  px-24 mb-20 items-center gap-[43px]'>
                {card.map((c, index) => (
                    <div key={index} className=''>
                        <div className='w-[267px] h-[323px] p-[20px_10px_20px_10px] shadow-[1px_1px_8px_2px_#515A9340] flex flex-col items-center gap-[10px]'>

                            <div className='w-[237px] flex justify-center'>
                                <img src={c.icon} alt='icon1' className='w-[60.33px] h-[59.49px]' />

                            </div>
                            <p className='text-[#3C4858] text-center font-[900] font-[20px]'>
                                {c.title}
                            </p>
                            <p className='font-[#161C2D] text-left font-[16px] leading-[24px] '>
                                {c.desc}
                            </p>
                        </div>

                    </div>
                ))}
            </div>


            <div className='flex flex-col gap-[36px] px-80'>
                <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>SPARSE SCHOOL/COLLEGE ERP FEATURES</p>
                <p className='font-nunito text-[16px] font-medium leading-[25.6px] text-center'>The benefits of sPARSE school/College ERP are unlimited. The ERP's user interface is an adventure in and of itself. To ensure the implementation and maintenance of each module, the ERP's modules are interconnected but separate.
                </p>
            </div>


            <div className='bg-[#EAEEFB] h-[485px] flex flex-col justify-center mb-20'>
                <div className='flex flex-col gap-[32px]'>
                    <Slider {...settings} className="overflow-hidden w-full px-10 my-3">

                        {items.map((item) => (
                            <div className=''>
                                <div className='w-[350px] h-[349px] py-[24px] px-[1px] flex flex-col items-center bg-[white] gap-[8px] rounded-lg'>

                                    <div className=' h-[90%] flex flex-col items-center gap-[10px] px-6'>
                                        <img src={item.icon} alt='icon1' className='w-[60.33px] h-[59.49px]' />


                                        <p className='text-[#3C4858] text-center font-[900] font-[20px]'>
                                            {item.title}
                                        </p>
                                        <p className='font-[#161C2D] text-center font-[16px] leading-[24px]'>
                                            {item.desc}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>


            <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Key Benefits of Sparse School/College ERP</p>

            <div className='text-[#161C2D] flex w-full gap-[36px] justify-center mb-28'>
                {benefits.map((b, index) => (
                    <div key={index} className='w-[255px] h-[432px] py-10 px-4 gap-5 flex flex-col rounded-lg shadow-[1px_1px_8px_1px_#3C485826]'>
                        <p className='text-[18px] font-extrabold leading-[27px] text-center text-[#161C2D]'>{b.p1}</p>
                        <p className='text-[16px] '>{b.p2}</p>
                    </div>
                ))}

            </div>

        </div>
    )
}

export default ERP