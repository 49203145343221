// import React, { useState } from 'react'
// import bg from '../assets/GIThome.png'
// import { FaUser } from 'react-icons/fa';
// const GetInTouchHome = () => {

//     // const [formData, setFormData] = useState({
//     //     username: '',
//     // });

//     // const handleChange = (e) => {
//     //     setFormData({
//     //         ...formData,
//     //         [e.target.name]: e.target.value,
//     //     });
//     // };

//     // const handleSubmit = (e) => {
//     //     e.preventDefault();
//     //     console.log(formData);
//     // };

//     return (
//         <div className='h-[646px] relative '>
//             <div className='z-0'>
//                 <img className='' src={bg} alt='get in touch' />
//             </div>
//             <div className='z-10'>
//                 <p className='text-[#EBEBEB] text-[40px] font-semibold leading-[36px] text-left absolute top-20 left-32'>Get In Touch !</p>
//                 {/* <form onSubmit={handleSubmit} className="max-w-sm mx-auto p-4">
//                     <div className="relative">
//                         <span className="absolute inset-y-0 left-0 flex items-center pl-3">
//                             <FaUser className="text-gray-500" />
//                         </span>
//                         <input
//                             type="text"
//                             name="username"
//                             value={formData.username}
//                             onChange={handleChange}
//                             placeholder="Username"
//                             className="w-full py-2 pl-10 pr-4 border border-gray-300 rounded-lg focus:outline-none-lg focus:ring-2-lg focus:ring-blue-500"
//                         />
//                     </div>

//                     <button type="submit" className="mt-4 px-4 py-2 bg-blue-500 text-white rounded">
//                         Submit
//                     </button>
//                 </form> */}

//                 <form className='w-[736px] h-[620px] p-[54px_34px_36px_34px] gap-[8px] rounded shadow-[0px_4px_4px_0px_#00000040] absolute left-32 top-32'>
//                     <div className='relative'>
//                         <span className="absolute inset-y-0 left-0 flex items-center pl-3">
//                             <FaUser className="text-gray-500" />
//                         </span>
//                         <input
//                             type='text'
//                             name='first name'
//                             placeholder='Your Name:'
//                             className="border-2 border-gray-500  py-2 pl-10 pr-4 border border-gray-300 rounded-lg focus:outline-none-lg focus:ring-2-lg focus:ring-blue-500"
//                         />
//                     </div>
//                 </form>
//             </div>
//         </div>
//     )
// }

// export default GetInTouchHome


import React, { useState } from 'react'
import bg from '../assets/GIThome.png'
import { BsPerson, BsTelephone } from "react-icons/bs";
import { MdOutlineMail } from "react-icons/md";
import { FiBook } from "react-icons/fi";
import { FaRegComment } from "react-icons/fa6";


const GetInTouchHome = () => {
    return (
        <div className='h-[646px] relative mb-64'>

            <div className='absolute inset-0 z-0'>
                <img className='w-full h-full object-cover' src={bg} alt='get in touch' />
            </div>

            <div className='relative z-10'>
                <p className='text-[#EBEBEB] text-[40px] font-semibold leading-[36px] text-left absolute top-20 left-32'>
                    Get In Touch !
                </p>

                <form className='w-[736px] h-[620px] p-[54px_34px_36px_34px] flex flex-col gap-[32px] rounded-[12px] shadow-[0px_4px_4px_0px_#00000040] absolute left-32 top-40 bg-white'>

                    <div className='flex gap-[32px] w-[668px] h-auto items-center justify-center'>

                        <div className='w-[261px] h-[73px]'>
                            <label className='text-[14px] font-bold leading-[21px] text-left'>
                                Your Name <span className='text-[#E43F52]'>*</span>
                            </label>
                            <div className='relative w-full my-3'>
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <BsPerson className='text-xl' />
                                </span>
                                <input
                                    type='text'
                                    name='your name'
                                    placeholder='Your Name:'
                                    className="border-2 w-full border-2 border-[#DEE2E6] py-2 pl-10 pr-4 rounded-lg focus:outline-none-lg focus:ring-2-lg focus:ring-blue-500"
                                />
                            </div>
                        </div>

                        <div className='w-[375px] h-[73px]'>
                            <label className='text-[14px] font-bold leading-[21px] text-left'>
                                Your Name <span className='text-[#E43F52]'>*</span>
                            </label>
                            <div className='relative w-full my-3'>
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <BsTelephone className='text-lg' />
                                </span>
                                <input
                                    type='tel'
                                    name='your mobile'
                                    placeholder='Your Mobile:'
                                    className="border-2 w-full border-2 border-[#DEE2E6] py-2 pl-10 pr-4 rounded-lg focus:outline-none-lg focus:ring-2-lg focus:ring-blue-500"
                                />
                            </div>
                        </div>
                    </div>

                    <div className='w-[668px] h-auto flex flex-col gap-[22px]'>
                        <div className='w-full h-[73px]'>
                            <label className='text-[14px] font-bold leading-[21px] text-left'>
                                Your Email <span className='text-[#E43F52]'>*</span>
                            </label>
                            <div className='relative w-full my-3'>
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <MdOutlineMail className='text-xl' />
                                </span>
                                <input
                                    type='text'
                                    name='your email'
                                    placeholder='Your Email:'
                                    className="border-2 w-full border-2 border-[#DEE2E6] py-2 pl-10 pr-4 rounded-lg focus:outline-none-lg focus:ring-2-lg focus:ring-blue-500"
                                />
                            </div>
                        </div>


                        <div className='w-full h-[73px]'>
                            <label className='text-[14px] font-bold leading-[21px] text-left'>
                                Subject
                            </label>
                            <div className='relative w-full my-3'>
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <FiBook className='text-lg' />
                                </span>
                                <input
                                    type='text'
                                    name='subject'
                                    placeholder='Subject'
                                    className="border-2 w-full border-2 border-[#DEE2E6] py-2 pl-10 pr-4 rounded-lg focus:outline-none-lg focus:ring-2-lg focus:ring-blue-500"
                                />
                            </div>
                        </div>



                        <div className='w-full h-[73px]'>
                            <label className='text-[14px] font-bold leading-[21px] text-left'>
                                Comments
                            </label>
                            <div className='relative w-full my-3'>
                                <span className="absolute inset-y-0 left-0 top-3 pl-3">
                                    <FaRegComment className='text-lg' />
                                </span>
                                <textarea
                                    type='text'
                                    rows='4'
                                    name='comment'
                                    className="border-2 w-full border-2 border-[#DEE2E6] py-2 pl-10 pr-4 rounded-lg focus:outline-none-lg focus:ring-2-lg focus:ring-blue-500"
                                />
                            </div>
                        </div>




                    </div>

                    <button className='bg-[#0058AE] text-white w-[668px] h-[40px] text-center z-20 absolute bottom-10 '>
                        Send
                    </button>

                </form>
            </div>
        </div>
    )
}

export default GetInTouchHome
