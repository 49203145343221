import React from 'react'
import bgImage from '../../assets/servicesImages/uiux/hero.png'
import uiux from '../../assets/servicesImages/uiux/uiux.png'
import uiux2 from '../../assets/servicesImages/uiux/uiux2.png'
import bottom from '../../assets/servicesImages/uiux/last.png'
import a from '../../assets/servicesImages/uiux/a.png'
import b from '../../assets/servicesImages/uiux/b.png'
import c from '../../assets/servicesImages/uiux/c.png'
import d from '../../assets/servicesImages/uiux/d.png'
import e from '../../assets/servicesImages/uiux/e.png'
import a2 from '../../assets/servicesImages/uiux/1.png'
import b2 from '../../assets/servicesImages/uiux/2.png'
import c2 from '../../assets/servicesImages/uiux/3.png'
import d2 from '../../assets/servicesImages/uiux/4.png'
import e2 from '../../assets/servicesImages/uiux/5.png'
import f2 from '../../assets/servicesImages/uiux/6.png'
import g2 from '../../assets/servicesImages/uiux/7.png'
import Slider from 'react-slick'


const items = [
    {
        image: a,
        title: 'UI/UX strategy & consulting',
        desc: 'Our design thinking expert consultants will get in touch with you to ideate, strategize and formulate the best possible design for your business goals and objectives.',
    },
    {
        image: b,
        title: 'SEO-friendly designs',
        desc: 'Performance testing determines the speed, responsiveness, and stability under different test case scenarios and workloads. With our rich experience resolves these issues through application behavior analysis and quality checks.',
    },
    {
        image: c,
        title: 'Sitemap & wireframing',
        desc: "Based of the user stories we write test cases and our QA and testing team conducts repetitive tests as a part of exploratory testing. The comprehensive test coverage strategy reviews the application from the user's perspective.",
    },
    {
        image: d,
        title: 'Responsive design',
        desc: "We identify potential vulnerabilities and threats through rigorous information security test scenarios. Risk assessment and penetration testing are done before and after release to defend your application from malicious threats which would hinder the application's function. ",
    },
    {
        image: e,
        title: 'Cross-browser compatibility',
        desc: 'We optimize the website technically, visually, and aesthetically to provide a consistent user experience across browsers to your customers either on mobile or on bigger screen.',
    },

]


const items2 = [
    {
        image: a2,
        title: 'Comprehensive quality assessment',
        desc: 'Our designers ensure that UI/UX & websites are designed based on customers needs, wants, and behavior which will enhance your customer engagement and revenue generation.',
    },
    {
        image: b2,
        title: 'Robust informational architecture',
        desc: 'Clear UI/UX, understandable, easy to user and SEO-friendly content with custom website design layout with relevant headlines.',
    },
    {
        image: c2,
        title: 'Collaborative research and consultation',
        desc: "Creating latest web applications UI/UX design with ideas brainstormed from technological and business enhancement perspectives to maintain transparent and open communication",
    },
    {
        image: d2,
        title: 'Highly secure and scalable applications',
        desc: "Get your web applications developed with security and scalability at the core which will help you respond quickly and easily to your customer requirements.",
    },
    {
        image: e2,
        title: 'Customized competitive designs',
        desc: 'In-depth competitor and market analysis is done to ensure that website designs are up to date and equipped to provide you a significant competitive edge.',
    },
    {
        image: f2,
        title: 'Responsive & fluid designs',
        desc: 'Reach more audiences, get high engagement and conversion rates with a responsive design that reaches all customers irrespective of the device being used.',
    },
    {
        image: g2,
        title: ' On-Demand website update',
        desc: 'We understand the importance of keeping up your website & UI/UX with the latest technological advancements and hence we provide on-demand website updates to help you compete with your competitors.',
    },


]

const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 4.2,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
};

const settings2 = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 4.2,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
};

const UIUX = () => {
    return (
        <div className='flex flex-col gap-[64px]'>

            <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
                style={{ backgroundImage: `url(${bgImage})` }}>
                <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)]  z-0"></div>
                <div className='flex flex-col gap-[48px] p-28 z-10'>
                    <div className='flex flex-col w-[470px] h-[270px] gap-[32px]'>
                        <p className='font-nunito text-[48px] leading-[67.2px] text-left'>
                            UI/UX Design
                        </p>
                        <p className="text-[18px] w-[500px] font-normal leading-[25.6px] text-left">
                            Create a customer-centric, interactive, and elegant UI/ UX design with Nextwebi for higher business engagement.                        </p>
                    </div>
                </div>
            </div>
            <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Performance-driven agile UI/UX design services</p>

            <div className='flex mx-20 gap-20'>
                <div className='text-[20px] w-3/4 leading-[50px] flex flex-col gap-10'>
                    <p>
                        At Esparse Matrix Solutions, we create hyper-personalized, responsive web designs with interactive UIs to enhance user experiences and boost customer conversion. Our tailored UI/UX designs reduce bounce rates, improve loading times, and offer seamless navigation, giving you a competitive edge.
                    </p>
                    <p>
                        We focus on understanding your business goals through thorough analysis and pre-development surveys. This ensures our design concepts meet your expectations and drive your marketing objectives, delivering a website that is both visually appealing and results-driven.
                    </p>
                </div>


                <img src={uiux} alt='uiux' className='w-[586px] h-[468px]' />
            </div>
            <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Why customised UI/UX design service</p>
            <Slider {...settings} className="overflow-hidden w-full px-10">

                {items.map((item) => (
                    <div>
                        <div className='w-[292px] h-[447px] border rounded-lg flex flex-col gap-2'>

                            <img src={item.image} alt='card' className='h-[199.31px] rounded-tr rounded-tl' />
                            <p className='text-[20px] font-semibold  text-center px-2'>{item.title}</p>
                            <p className='text-[16px] leading-[25px] px-2'>{item.desc}</p>
                        </div>
                    </div>
                ))}
            </Slider>
            <div className='flex flex-col gap-[36px] px-28'>
                <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Key differentiator</p>
                <p className='text-[16px] leading-[25.6px] text-justify'>
                    Esparse Matrix Solutions  is a leading web designing company, dedicated to designing websites that entice visitors to become your customers all over the world. Being a web design company with the objective of "Imagination meets Implementation," we are connected with various businesses to help them amplify their organization’s branding and visibility through our custom-made responsive web designs. Team Esparse Matrix Solutions  corroborates that they deliver user-friendly, faster loading, and highly compatible websites with an exceedingly coherent user interface. Some of our unique features include:
                </p>
            </div>
            <Slider {...settings2} className="overflow-hidden w-full px-10">

                {items2.map((item) => (
                    <div>
                        <div className='w-[292px] h-[447px] border rounded-lg flex flex-col gap-5'>

                            <img src={item.image} alt='card' className='h-[199.31px] rounded-tr rounded-tl' />
                            <p className='text-[20px] font-semibold  text-center px-2'>{item.title}</p>
                            <p className='text-[16px] leading-[25px] px-2'>{item.desc}</p>
                        </div>
                    </div>
                ))}
            </Slider>

            <div className='flex mx-20 gap-20'>
                <div className=' w-3/4 flex flex-col gap-10'>
                    <div>
                        <li className='text-[16px] font-semibold'>Information gathering & analysis</li>
                        <p className='text-[14px] px-5 pt-2'>Detailed information is collected and in-depth analysis is done on the web designs qualitative,   descriptive and technical aspects to meet the client’s expectations, business objectives, and goals.</p>
                    </div>

                    <div>
                        <li className='text-[16px] font-semibold'>Wireframing</li>
                        <p className='text-[14px] px-5 pt-2'>A wireframe & sitemap is created to strategically present and communicate the information architecture and navigational flow of the website UI/UX.</p>
                    </div>

                    <div>
                        <li className='text-[16px] font-semibold'>UI/UX & Website Designing</li>
                        <p className='text-[14px] px-5 pt-2'>Website is created using the latest UI Tools and technologies with engaging and unique graphics to meet the business needs.</p>
                    </div>

                    <div>
                        <li className='text-[16px] font-semibold'>Usability testing & hosting</li>
                        <p className='text-[14px] px-5 pt-2'>A comprehensive set of usability testing is conducted to check the functionality of the website in real world. Post finalization the website goes live to drive conversions for your business.</p>
                    </div>
                
                </div>


                <img src={uiux2} alt='uiux' className='w-[586px] h-[420px]' />
            </div>

            <div className='flex flex-col gap-[36px] px-28 mb-16'>
                <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Unique UI/UX design service features</p>
                <p className='text-[16px] leading-[25.6px] text-justify'>
                Accelerate your business growth with advanced compelling website designsand visually attractive UI/UX features with one of the leading web design company in Bangalore, India. We analyze, strategize and formulate customized website designs to help you attract and retain your customers and create a strong brand identity online.
                </p>
                <img src={bottom} alt='bottom' />
            </div>
        </div>
    )
}

export default UIUX