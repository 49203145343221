import React from 'react'

const HomeStats = () => {
    return (
        <div className='bg-[#EAEEFB] h-[250px] mx-24 p-[68px_88px] flex justify-between items-center'>

            <div className='bg-[#fff] w-[321px] h-[165px] pt-[4px] px-[15px] flex flex-col gap-[7.2px] rounded'>
                <p className='text-center text-[56px] font-semibold leading-[75px] text-[#2F55D4]'>100+</p>
                <p className='text-[20px] font-bold leading-[25.6px] text-center text-[#2F55D4] px-20'>PROJECTS DELIVERED</p>
            </div>
            <div className='bg-[#fff] w-[321px] h-[165px] pt-[4px] px-[15px] flex flex-col gap-[7.2px] rounded'>
                <p className='text-[56px] font-semibold leading-[75px] text-center text-[#2F55D4]'>4+</p>
                <p className='text-[20px] font-bold leading-[25.6px] text-center text-[#2F55D4] px-20'>YEARS IN OPERATION</p>
            </div>
            <div className='bg-[#fff] w-[321px] h-[165px] pt-[4px] px-[15px] flex flex-col gap-[7.2px] rounded'>
                <p className='text-[56px] font-semibold leading-[75px] text-center text-[#2F55D4]'>1</p>
                <p className='text-[20px] font-bold leading-[25.6px] text-center text-[#2F55D4] px-20'>GLOBAL OFFICES</p>
            </div>

        </div>
    )
}

export default HomeStats