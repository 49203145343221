import React from 'react'
import bgImage from '../../assets/servicesImages/crm/hero.png'
import Slider from 'react-slick'
import card1 from '../../assets/servicesImages/crm/1.png'
import card2 from '../../assets/servicesImages/crm/2.png'
import card3 from '../../assets/servicesImages/crm/3.png'
import card4 from '../../assets/servicesImages/crm/4.png'
import card5 from '../../assets/servicesImages/crm/5.png'
import card6 from '../../assets/servicesImages/crm/6.png'
import icon1 from '../../assets/servicesImages/crm/icon1.png'
import icon2 from '../../assets/servicesImages/crm/icon2.png'
import icon3 from '../../assets/servicesImages/crm/icon3.png'
import icon4 from '../../assets/servicesImages/crm/icon4.png'



const cards = [
    {
        image: card1,
        title: 'Contact Management',
        desc: 'Manage your clients, prospects, and vendors, as well as all of your contacts and follow-up activities.  '
    },
    {
        image: card2,
        title: 'Sales Pipeline Management',
        desc: "Keep track of the sales leads and prioritise them in the sales process. "
    },
    {
        image: card3,
        title: 'Reporting',
        desc: 'With a single click, create detailed management information reports.  '
    },
    {
        image: card4,
        title: 'Sales Quotations',
        desc: 'Create quotes based on your business opportunities and deliver them to your clients directly.   '
    },
    {
        image: card5,
        title: 'Importing leads from Excel',
        desc: 'Can you have a large amount of data to import? You can conveniently import data using our bulk import function by simply importing an excel file.     '
    },
    {
        image: card6,
        title: 'User Roles & Profiles',
        desc: 'You will control which users in your company have access to which data and fields. You have full control of what things they can see and what decisions they can take.      '
    }

]


const card = [
    {
        icon: icon1,
        title: 'Simple to use',
        desc: 'As a result of the reduced paper work, there is a higher yield per working hour. Per mission, the number of workers has been reduced. Monitoring in real time leads to a more effective Method.    '
    },
    {
        icon: icon2,
        title: 'Focus on your activities',
        desc: 'Client feedback, market, organisational culture, and other criteria were used to build this. Flexible to adapt to the needs of the company as it expands.   '
    },
    {
        icon: icon3,
        title: 'UMake better decisions',
        desc: 'Making managerial decisions becomes easy. Performance metrics are fed into the system and can be tracked in real time for feedback.   '
    },
    {
        icon: icon4,
        title: 'Increase the performance',
        desc: 'Easily structured for Data analytics for board sessions, and evaluation  meetings, among other things. Within minutes of performing calculation  '
    },
]

const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 4,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
};

const CRM = () => {
    return (
        <div className='flex flex-col gap-[64px]'>

            <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
                style={{ backgroundImage: `url(${bgImage})` }}>
                <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)]  z-0"></div>
                <div className='flex flex-col gap-[48px] p-28 z-10'>
                    <div className='flex flex-col w-[470px] h-[270px] gap-[32px]'>
                        <p className='font-nunito text-[48px] leading-[67.2px] text-left'>
                            ESPARSE CRM SOLUTIONS
                        </p>
                        <p className="text-[18px] w-[500px] font-normal leading-[25.6px] text-left">
                            Help Your Business grow By Nurturing Leads
                        </p>
                    </div>
                </div>
            </div>


            <div className='flex flex-col gap-[36px] px-52'>
                <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>About ESPARSE CRM</p>
                <p className='font-nunito text-[16px] font-medium leading-[25.6px] text-justify'>Esparse CRM software creates new tasks for your team. Automate everything from appointment reminders to follow-up emails by putting your calendar online. With complete contact views, deal tracks, real-time DATA, and other features, you can achieve a significantly higher ROI. In the Sales CRM, the contact timeline contains all of the relevant details about the customer's progress.
                </p>
            </div>


            <div className='flex flex-col gap-[36px] px-28'>
                <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Esparse CRM Key Features</p>
                <p className='font-nunito text-[16px] font-medium leading-[25.6px] text-center'>Human resources are unique and provide a significant competitive edge to every business. In today's demanding climate, attracting and keeping the best workforce has been the most difficult task. The Esparse crm allows smooth incorporation between departmental, intra-departmental, and entity levels.
                </p>
            </div>



            <div className=' h-[500px] flex flex-col justify-center'>
                <div className='flex flex-col gap-[29px]'>
                    <Slider {...settings} className="overflow-hidden w-full px-10 my-3">

                        {cards.map((item) => (
                            <div className='py-10 '>

                                <div className='w-[292px] h-[405px] rounded-lg shadow-[1px_4px_4px_1px_#00000036]'>
                                    <img src={item.image} alt='card' className='h-[200px] w-full rounded-tl-lg rounded-tr-lg' />
                                    <div className='text-[#3C4858] mt-1'>
                                        <p className='text-[20px] font-extrabold leading-[30px] tracking-[0.5px] text-center h-[60px] px-2'>{item.title}</p>
                                        <p className='text-[14px] font-normal leading-[25.6px] text-center px-5'>{item.desc}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>


            <div className='flex flex-col gap-[36px] px-56'>
                <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Why ESPARSE HRMS?</p>
                <p className='font-nunito text-[16px] font-medium leading-[25.6px] text-center'>Esparse HRMS is useful because it allows for more operating hours a day. The presented personalised HRMS approach eliminates the system's flaws.
                    See how HRMS benefits our customers.</p>
            </div>




            <div className='flex justify-center  px-24 mb-20 items-center gap-[43px]'>
                {card.map((c, index) => (
                    <div key={index} className=''>
                        <div className='w-[267px] h-[300px] p-[20px_10px_20px_10px] shadow-[1px_1px_8px_2px_#515A9340] flex flex-col items-center gap-[10px]'>

                            <div className='w-[237px] flex justify-center'>
                                <img src={c.icon} alt='icon1' className='w-[60.33px] h-[59.49px]' />

                            </div>
                            <p className='text-[#3C4858] text-center font-[900] font-[20px]'>
                                {c.title}
                            </p>
                            <p className='font-[#161C2D] text-center font-[16px] leading-[24px] px-5'>
                                {c.desc}
                            </p>
                        </div>

                    </div>
                ))}
            </div>


        </div>
    )
}

export default CRM