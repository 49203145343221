import React from 'react';
import Slider from "react-slick";
import icon1 from '../assets/homeApproachesSection/icon1.png';
import icon2 from '../assets/homeApproachesSection/icon2.png';
import icon3 from '../assets/homeApproachesSection/icon3.png';
import icon4 from '../assets/homeApproachesSection/icon4.png';
import icon5 from '../assets/homeApproachesSection/icon5.png';
import icon6 from '../assets/homeApproachesSection/icon6.png';
// import nextArrowImg from '../assets/arrowNext.png';
// import prevArrowImg from '../assets/arrowPrev.png';

// const SampleNextArrow = (props) => {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ 
//         ...style, 
//         display: "block", 
//         background: `url(${nextArrowImg}) no-repeat center center`,
//         backgroundSize: 'contain',
//         width: '10%',
//         height: '56px',
//         cursor: 'pointer'
//       }}
//       onClick={onClick}
//     />
//   );
// }

// const SamplePrevArrow = (props) => {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ 
//         ...style, 
//         display: "block", 
//         background: `url(${prevArrowImg}) no-repeat center center`,
//         backgroundSize: 'contain',
//         width: '5%',
//         height: '56px',
//         cursor: 'pointer'
//       }}
//       onClick={onClick}
//     />
//   );
// }

const approaches = [
  {
    icon: icon1,
    title: 'Discussion and Analysis',
    desc: 'Together with our software developers and analysts, we begin by discussing and analyzing our client’s needs, sketching the outline of the desired software development services, identifying gaps and proposing Software development solutions along the way.',
    link: ''
  },
  {
    icon: icon2,
    title: 'Prototyping',
    desc: 'We help our clients and their end- users visualize the look-and-feel and interaction of the finished product before it is actually built using state- of-the-art design and prototyping tools. ',
    link: ''
  },
  {
    icon: icon3,
    title: 'Software Specifications',
    desc: 'We write a Software development specifications document (Software development blueprint) that ensures that our clients get exactly what they expected Software development services and agreed to - in terms of functionality and the product’s UI and UX design.',
    link: ''
  },
  {
    icon: icon4,
    title: 'Software Development Approach',
    desc: 'We use agile software development style alongside the traditional approaches to ensure reliability of delivery schedule and quality of the end product.  ',
    link: ''
  },
  {
    icon: icon5,
    title: 'Rapid Application Development',
    desc: 'Our software developers make use of cutting-edge tools and software development frameworks to ensure quality code construction in the shortest time possible.',
    link: ''
  },
  {
    icon: icon6,
    title: 'Software Development Approach',
    desc: 'Our software development team is exceptionally skilled in planning the deployment and maintenance of large, complex, and high-visibility software development systems and mobile apps.',
    link: ''
  },
]

const settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  slidesToShow: 4,
  speed: 500,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
  // nextArrow: <SampleNextArrow />,
  // prevArrow: <SamplePrevArrow />
};

const OurApproach = () => {
  return (
    <div className='h-[488px] flex flex-col gap-[36px] justify-center items-center mx-10 '>
      <p className='text-[32px] font-semibold leading-[23.4px] text-center'>
        Our Approach
      </p>
      <Slider {...settings} className="overflow-hidden w-full px-8">
        {approaches.map((a, index) => (
          <div key={index} className='flex justify-center items-center my-5 gap-[16px]'>
            <div className='w-[304px] h-[404px] p-[24px_18px] gap-[8px] flex flex-col items-center justify-center shadow-[1px_1px_8px_2px_#515A9340]'>
              <div className='h-[307px] flex flex-col items-center gap-[8px]'>
                <div className=''>
                  <img src={a.icon} alt='icon' className='w-[48.52px]' />
                </div>
                <p className='text-[16px] font-bold leading-[36px] text-center'>
                  {a.title}
                </p>
                <p className='text-[16px] font-normal leading-[25.6px] text-left'>
                  {a.desc}
                </p>
              </div>
              <button className='bg-[#006BD3] w-[112px] h-[42px] p-[8px_12px] gap-[8px] rounded text-[#FFF]'>
                View Detail
              </button>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default OurApproach;
