import React from 'react'
import bgImage from '../assets/blog/hero2.png'
import banner from '../assets/blog/card.png'
import card from '../assets/blog/card.png'
import { MdKeyboardArrowRight } from "react-icons/md";


const cards = new Array(3).fill(
    {
        image: card,
        title: 'How Might A Mobile App Help Your Non-Profit Company?',
        desc: "Initially, the purpose of mobile apps was to promote productivity by collecting contacts, scheduling emails, and managing calendars.by collecting contacts, scheduling emails, and managing calendars. inspired to produce apps for a variety of niches, including games, entertainment, social networks, and n...",
        link: '/read'

    }
)
const SingleBlog = () => {
    return (
        <div className='flex flex-col gap-[64px]'>

            <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
                style={{ backgroundImage: `url(${bgImage})` }}>
                <div className="absolute inset-0 bg-gradient-to-r from-[#171f81]/[.33] to-[#c5cfeff0]/[.18] z-0"></div>
                <div className='flex flex-col gap-[48px] p-28 z-10'>
                    <div className='flex flex-col w-[810px] '>
                        <p className='font-nunito text-[48px] leading-[67.2px] text-left'>
                            Blog: How might A Mobile app Help Your Non- Profit company?
                        </p>
                    </div>
                </div>
            </div>

            <p className='text-center text-[32px] font-semibold leading-[23.4px]'>BLOGs</p>
            <div className='m-28 mb-0 mt-0 flex flex-col gap-[64px]'>
                <img src={banner} alt='banner' className='w-full rounded-lg h-[501.86px]' />
                <div className='border-2 p-10 w-full'>
                    Blog Author: Esparse Matrix Solutions
                    <br />
                    Posted on: 21-06-2023
                    <br />
                    <br />

                    Initially, the purpose of mobile apps was to promote productivity by collecting contacts, scheduling emails, and managing calendars. Because of their broad popularity, app developers have been inspired to produce apps for a variety of niches, including games, entertainment, social networks, and news.
                    <br />
                    <br />

                    Our non-profit organization's mobile app is a user-friendly platform that enables easy access to our mission, services, and engagement opportunities. With personalized content, push notifications, and interactive features, it actively connects users with our cause.
                    <br />

                    <br />

                    Here are some reasons on why mobile apps for nonprofits are essential right now:
                    <br />
                    <br />

                    1.Increased Outreach: An app allows NGOs to reach a wider audience and engage with people who prefer using mobile devices. With an app, NGOs can promote their mission, share information about their projects, and raise awareness about social issues. This can lead to increased visibility and attract more volunteers, donors, and supporters.
                    <br />
                    <br />

                    2.Enhanced Communication: An app gives the NGO and its stakeholders a simple and direct channel for  communication. The NGO can notify its audience about its activities, events, and emergencies thanks to the real-time updates, notifications, and alerts it supports. Additionally, it promotes two-way contact so consumers may give feedback, ask questions, or request help.
                    <br />
                    <br />


                    3. Donations & Fundraising: To support their initiatives, many NGOs rely on donations and fundraising activities. By offering a safe online transaction platform, an app can speed up the donation process. Additionally, it can make it easier for individuals to donate by enabling NGOs to start fundraising campaigns, publish development updates, and send financial appeals straight to app users.
                    <br />
                    <br />

                    4. Management of Volunteers: NGOs frequently depend on volunteers to carry out their activities. An app can act as the central hub for managing volunteers, enabling users to sign up as volunteers, browse opportunities, sign up for particular projects, track their hours, and interact with the NGO's employees. It streamlines the process of finding volunteers and enhances overall coordination.
                    <br />
                    <br />

                    5. Access to Resources: NGOs may provide a range of resources, including books, training manuals, and research papers. A centralized platform for sharing and storing these resources can be offered through an app, making it simple for users to access them. This improves the NGO's ability to share information and provide people with useful knowledge.
                    <br />
                    <br />

                    6. Collection Of Data And Analysis: An app can help NGOs gather information about their operations, results, and beneficiaries. NGOs can acquire important information and use the data to evaluate their success, make wise decisions, and enhance their programmes by including forms, surveys, or feedback mechanisms within the app.
                    <br />
                    <br />

                    7. Mobilization and Advocacy: NGOs frequently participate in advocacy work to promote social or policy changes. A strong tool for organizing supporters is an app that allows users to take part in campaigns, sign petitions, post content on social media, or get in touch with their politicians. The lobbying efforts of the NGO are strengthened and have a greater impact as a result.
                    <br />
                    <br />

                    8. Community Building: An app may help to strengthen the bonds between donors, recipients, and volunteers. Users may interact, work together, and share experiences with the help of services like discussion forums, social networking, and event calendars.
                    <br />
                    <br />

                    By utilizing the widespread availability of mobile devices and offering a smooth user experience, an app can dramatically improve an NGO's reach, communication, efficiency, and effect. It enables NGOs to better adapt to the digital age, engage with their audience, and accomplish their objectives.
                    <br />
                    <br />


                    <strong>Why is Esparse Matrix Solutions a perfect tech partner for your NGO?</strong>
                    <br />
                    <br />

                    Esparse Matrix Solutions is a privately held firm that provides software services with an emphasis on improving people's lives through cutting-edge technology. Our products improve lives and give people and businesses the tools they need to succeed. Additionally, we have developed  app platforms for our clients' NGO .
                    <br />
                    <br />

                    Esparse Matrix Solutions, the industry leader in developing web and mobile applications for non-profit organizations, will advise you on the market, product, and business strategy for mobile solutions for applications that facilitate donations to charities. Both the iOS and Android platforms offer a great mobile app development environment for non-profit organizations and donation services.
                    Talk to our technological specialists if you manage an NGO and have a great idea for a wonderful NGO app that will benefit a good cause.

                </div>
            </div>
            <p className='text-center text-[32px] font-semibold leading-[23.4px]'>Related Posts..</p>
            <div className='flex mb-10 gap-5 w-[100%] justify-center'>

                {cards.map((c, index) => (
                    <div className='w-[400px] h-[548px] shadow-[0px_4px_4px_0px_#00000040]' key={index}>
                        <img src={c.image} className='h-[195px]' alt='card' />
                        <div className='flex flex-col p-4 gap-[24px]'>

                            <p className='text-[20px] font-bold text-left text-[#3C4858]'>{c.title}</p>
                            <p className='text-[16px] text-left text-[#3C4858]'>{c.desc}</p>
                            <div className='text-[#2F55D4] text-[16px] flex items-center gap-1'>
                                <a href={c.link}>Read More</a>
                                <MdKeyboardArrowRight className='text-[24px]' />
                            </div>
                        </div>
                    </div>
                ))}

            </div>
        </div>
    )
}

export default SingleBlog