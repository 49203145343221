import React from 'react'
import bgImage from '../../assets/servicesImages/appdev/hero.png'
import AppDevSection2 from '../../Pages/AppDevSection2'
import AppDevSection3 from '../../Pages/AppDevSection3'
import bottomImage from '../../assets/servicesImages/appdev/bottom.png'

const AppDev = () => {
    return (
        <div className='flex flex-col gap-[64px]'>

            <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
                style={{ backgroundImage: `url(${bgImage})` }}>
                <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)]  z-0"></div>
                <div className='flex flex-col gap-[48px] p-28 z-10'>
                    <div className='flex flex-col  h-[270px] gap-[32px]'>
                        <p className='font-nunito text-[48px] leading-[67.2px] text-left'>
                            Application Development
                        </p>
                        <p className="text-[18px] w-[470px] font-normal leading-[25.6px] text-left">
                            We are leading app developer for iOS, Android, and hybrid mobile platforms, we serve in different market segments and as per clients needs.                    </p>
                    </div>
                </div>
            </div>


            <div className='flex flex-col gap-[36px] px-64'>
                <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Application Development</p>
                <p className='font-nunito text-[16px] font-medium leading-[25.6px] text-justify'>Mobile Apps are making a major contribution to bringing Digital Transformation in the Business World while creating a rapid Buzz. Mobile Apps are one of the crucial parts of the Digital Transformation Strategy, which is efficiently digitalizing your business while improving the engagement of your Target Audience. Having a Business app is a must for every growing company as it provides Great User Experience while allowing you to reach your Tech-savvy Audience. So that our Team eSparse Matrix Solutions Pvt. Ltd. is offering you to unlock interactive mobile experiences for your customers at a budget- friendly price. We assure you that Using iOS mobile apps developed by one of the most trusted Mobile App Development Company in India will lead your company towards healthy finances with higher revenue and increased ROI. Keep your business ahead of Digital Transformation!</p>
            </div>
            <AppDevSection2 />
            <AppDevSection3 />
            <div className='h-fit text-[#fff] flex  justify-start items-center pb-24 '>
                <img src={bottomImage} alt='bottom' />

            </div>
        </div>
    )
}

export default AppDev