import React from 'react'
import Hero from '../Pages/Hero'
import HomeQuickServices from '../Pages/HomeQuickServices'
import HomeServices from '../Pages/HomeServices'
import WhyChooseUs from '../Pages/WhyChooseUs'
import OurApproach from '../Pages/OurApproach'
import HomeAbout from '../Pages/HomeAbout'
import HomeStats from '../Pages/HomeStats'
import ReadmoreHome from '../Pages/ReadmoreHome'
import GetInTouchHome from '../Pages/GetInTouchHome'

const Home = () => {
    return (
        <div>
            <div className='flex flex-col gap-[64px]'>
                <Hero />
                <HomeQuickServices />
                <HomeServices />
                <WhyChooseUs />
                <OurApproach />
                <HomeAbout />
                <HomeStats />
                <ReadmoreHome />
                <GetInTouchHome />
            </div>
        </div>
    )
}

export default Home