import React from 'react'

const HomeQuickServices = () => {
    return (
        <div className='flex flex-col gap-[36px] px-64'>
            <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Our Services</p>
            <p className='font-nunito text-[16px] font-medium leading-[25.6px] text-justify'>eSparse Matrix Solutions Private Limited is a Software agency in Pune. We will provide a one-stop solutions for all customer IT service needs, we will take care of all your needs right from Strategic concept software development, Business analysis, Digital Marketing, Software development to online marketing all in one place. Once you join with eSparse Matrix Solutions, you will never have to go anywhere else again. </p>
        </div>
    )
}

export default HomeQuickServices