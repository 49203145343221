import React from 'react'
import bgImage from '../assets/career/hero.png'
import { IoIosCheckmark } from "react-icons/io";
import { Link } from 'react-router-dom';



const cards = [
    {
        type: 'Full Time',
        role: 'Website Developer',
        exp: '1-2 year experience',
    },
    {
        type: 'Full Time',
        role: 'Flutter Developer',
        exp: '1+ Years Experience',
    },
    {
        type: 'Internship',
        role: 'UI/UX Internship',
        exp: 'Fresher',
    },
    {
        type: 'Internship',
        role: 'Graphic Design Intern',
        exp: '0-1 year experience',
    },
    {
        type: 'Internship',
        role: 'Andriod Developer',
        exp: '0-1 year experience',
    },
    {
        type: 'Internship',
        role: 'APP DEVELOPMENT INTERN',
        exp: '0-1 year experience',
    },
    {
        type: 'Full Time',
        role: 'Website Developer',
        exp: '1-2 year experience',
    },
    {
        type: 'Full Time',
        role: 'Website Developer',
        exp: '1-2 year experience',
    },
]


const Career = () => {
    return (
        <div className='flex flex-col gap-[64px]'>

            <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
                style={{ backgroundImage: `url(${bgImage})` }}>
                <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)]  z-0"></div>
                <div className='flex flex-col gap-[48px] p-28 z-10'>
                    <div className='flex flex-col w-[415px] h-[270px] gap-[32px]'>
                        <p className='font-nunito text-[48px] leading-[67.2px] text-left'>
                            ESPARSE MATRIX SOLUTIONS - New Job's
                        </p>
                    </div>
                </div>
            </div>


            <div className='flex flex-col gap-[36px] px-36'>
                <p className='text-center text-[32px] font-semibold leading-[23.4px]'>eSparse Matrix 360 Digital Marketing</p>
                <p className=' text-[16px] leading-[25.6px] text-justify'>Due to our extensive expertise, team of trained experts, core industry knowledge, and committed operating procedure, we are a leading custom software development firm that provides top-rated custom software development services. We recognise that every business has unique software specifications, and we provide a wide range of software development services to meet those needs.
                </p>
            </div>


            <div className='px-36'>
                <p className='text-left text-[22.61px] font-semibold mb-10'>Recommended Job :</p>

                <div className='flex flex-wrap mb-10 gap-5'>


                    {cards.map((data, index) => (
                        <div className='h-[263] w-[395.75px] rounded-lg bg-[#F8F9FC]' key={index}>

                            <div className='h-[55.14px] p-[13.57px_22.61px_13.57px_22.61px] rounded-tl-lg rounded-tr-lg bg-[#00000008] text-[#8492A6] text-left text-[18.09px] mb-2'>
                                <p>{data.type}</p>
                            </div>

                            <div className='h-[129px] p-[0px_10.18px_27.14px_10.18px] flex flex-col gap-3'>
                                <p className='text-[#161C2D] text-left text-[22.61px] px-5'>{data.role}</p>
                                <p className='text-[#8492A6] text-left text-[18.09px] px-5'>Requirements:</p>

                                <div className='flex items-center text-[#8492A6] text-left text-[16.28px]'>
                                    <IoIosCheckmark className='text-[30px]' />
                                    <p className='text-[#8492A6] text-left'>{data.exp}</p>
                                </div>
                            </div>

                            <div className='h-[76.62px] p-[14.7px_22.61px_13.57px_22.61px] rounded-bl-lg rounded-br-lg bg-[#00000008] text-[#8492A6] text-left text-[18.09px] border-top-2 border-[#00000020]'>
                                <Link to='/apply'>
                                    <button className='w-[348.26px] h-[48.35px] p-[10.18px_117.94px_10.18px_117.94px] text-[#2F55D4] border-2 border-[#2F55D4] rounded-lg'>View Details</button>
                                </Link>

                            </div>
                        </div>
                    ))}


                </div>
            </div>
        </div>


    )
}

export default Career