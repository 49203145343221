import React from 'react'
import card1 from '../assets/servicesImages/appdev/card1.1.png'
import card2 from '../assets/servicesImages/appdev/card2.1.png'
import card3 from '../assets/servicesImages/appdev/card3.1.png'


const cards = [
    {
        image: card1,
        title: 'Android Application Development',
        desc: "We've built over Various Android apps in a variety of categories, including on-demand, health & Live-streaming, and other popular categories. We build Android apps from the ground up using Java and Kotlin for anything."
    },
    {
        image: card2,
        title: 'ios Application Development',
        desc: "To include high-end iPhone app development services, we use modern programming languages such as Swift and Objective-C. We've developed iPhone applications in a variety of categories using tried-and-true technologies and methods."
    },
    {
        image: card3,
        title: 'Hybrid Application Development',
        desc: "Hybrid mobile applications are those that use the same code to work on a variety of mobile devices, such as iOS and Android. Many companies prefer hybrid apps because they are more flexible."
    }
]

const AppDevSection2 = () => {
    return (
        <div className='flex flex-col gap-[36px] px-52 mb-5'>
            <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Our Field of Specialization</p>
            <p className='font-nunito text-[16px] font-medium leading-[25.6px] text-justify'>We provide a wide range of mobile app development services that benefit the businesses and organisations in which we collaborate. Our dedicated team of mobile app developers provides a variety of services to meet the various business needs. The following programmes are our specialties: </p>

            <div className='w-full flex gap-[32px]  justify-center items-center'>
                {cards.map((c, index) => (
                    <div key={index} className='w-[350px] h-[480px] flex flex-col items-center rounded-lg shadow-[0px_8px_8px_0px_#3C485826]'>
                        <img src={c.image} alt='card' className='w-[250px] h-[200px] rounded-lg py-5' />
                        <p className='w-[302px] text-[20px] font-bold leading-[30px] text-center mb-4 px-4'>{c.title}</p>
                        <p className='w-[302px] text-[16px] leading-[25.6px] text-left'>{c.desc}</p>
                    </div>
                ))}
            </div>

        </div>
    )
}

export default AppDevSection2