import React from 'react'
import bgImage from '../assets/eventsImg/hero.png'
import Slider from 'react-slick';
import a from '../assets/eventsImg/1.png'
import b from '../assets/eventsImg/2.png'
import { MdKeyboardArrowRight } from 'react-icons/md';

const event = [
    {
        image: a,
        title: 'How To Crack Your Job Interview??',
        desc: "It's time to recruit on campus! It's almost time for students to begin their new chapter in life. The only thing standing between them and their ideal job in a fast-paced market is an interview. Mr.Vijay Rout, Personality Developer Trainer, presented his expertise and key useful lessons to ...",
        link: '/read'

    },
    {
        image: b,
        title: 'Live Webinar on “How Podcasts can help Business in 2021”',
        desc: "Join us on July 17, 2021 to learn more about How Podcasts can help Business IN 2021! Esparse Matrix Solutions Private Limited is going to organise a webinar on How Podcasts can help Business in 2021.The webinars are open for Startups, Entrepreneurs, Business Owners, Business People, ...",
        link: '/read'

    },
    {
        image: b,
        title: 'How To Crack Your Job Interview??',
        desc: "It's time to recruit on campus! It's almost time for students to begin their new chapter in life. The only thing standing between them and their ideal job in a fast-paced market is an interview. Mr.Vijay Rout, Personality Developer Trainer, presented his expertise and key useful lessons to ...",
        link: '/read'

    }
]
const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 2.3,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
};
const Events = () => {
    return (
        <div className='flex flex-col gap-[64px]'>

            <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
                style={{ backgroundImage: `url(${bgImage})` }}>
                <div className="absolute inset-0 bg-gradient-to-r from-[#171f81]/[.33] to-[#c5cfeff0]/[.18] z-0"></div>
                <div className='flex flex-col gap-[32px] p-28 z-10'>
                    <div className='flex flex-col w-[810px] '>
                        <p className='font-nunito text-[48px] leading-[67.2px] text-left'>
                            Events
                        </p>
                    </div>
                </div>
            </div>

            <p className='text-[32px] font-semibold leading-[23.4px] text-center'>
                eSparse Matrix 360 Digital Marketing
            </p>
            <Slider {...settings} className="overflow-hidden w-full px-5">
                {event.map((c, index) => (
                    <div key={index} className='flex justify-center  mb-20'>
                        <div className='w-[540px] h-[500px] gap-[13px] rounded-lg flex flex-col shadow-[1px_1px_8px_2px_#515A9340]'>
                            <img src={c.image} alt='icon' className=' h-[250px] rounded-tr-lg rounded-tl-lg w-full  object-cover' />
                            <div className=' w-full flex flex-col  gap-[8px] px-5 '>
                                <p className='text-[20px] leading-[36px] text-left'>
                                    {c.title}
                                </p>
                                <p className='text-[14px] font-normal leading-[20px]  text-[#8492A6]'>
                                    {c.desc}
                                </p>
                                <div className='text-[#8492A6] text-[16px] flex items-center gap-1'>
                                    <a href={c.link}>Read More</a>
                                    <MdKeyboardArrowRight className='text-[24px]' />

                                </div>
                            </div>

                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default Events