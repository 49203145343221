import React from 'react'
import icon1 from '../assets/homeServicesSection/icon1.png'
import icon2 from '../assets/homeServicesSection/icon2.png'
import icon3 from '../assets/homeServicesSection/icon3.png'
import icon4 from '../assets/homeServicesSection/icon4.png'
import icon5 from '../assets/homeServicesSection/icon5.png'
import icon6 from '../assets/homeServicesSection/icon6.png'
import icon7 from '../assets/homeServicesSection/icon7.png'
import image1 from '../assets/homeServicesSection/1.png'
import image2 from '../assets/homeServicesSection/2.png'
import image3 from '../assets/homeServicesSection/3.png'
import image4 from '../assets/homeServicesSection/4.png'
import image5 from '../assets/homeServicesSection/5.png'
import image6 from '../assets/homeServicesSection/6.png'
import image7 from '../assets/homeServicesSection/7.png'
import image8 from '../assets/homeServicesSection/8.png'
import image9 from '../assets/homeServicesSection/9.png'
import image10 from '../assets/homeServicesSection/10.png'
import Slider from "react-slick";

const services = [
    {
        id: 1,
        title: 'Software Development',
        description: 'Software development is bringing ideas to life in the digital world. It\'s like building with code, creating programs and apps that run on computers and devices.',
        image: image1,
        link: '',
        icon: icon1
    },
    {
        id: 2,
        title: 'Web Development',
        description: 'Web development is the process of bringing websites and web applications to life. It involves everything from the initial design,  planning, coding & Programming.',
        image: image2,
        link: '',
        icon: icon2
    },
    {
        id: 3,
        title: 'AI/ ML Devlopment',
        description: 'AI/ML (Artificial Intelligence/ Machine Learning) uses algorithms to enable machines to learn from data and perform tasks that traditionally require.',
        image: image3,
        link: '',
        icon: icon3
    },
    {
        id: 4,
        title: 'App development',
        description: 'Programming superpowers for your pocket. Its about creating mobile apps by coding the features you use every day.',
        image: image4,
        link: '',
        icon: icon4
    },
    {
        id: 5,
        title: 'FRONTEND: - ANGULAR/REACT/VUES',
        description: 'Angular, React, and Vue.js are popular frontend JavaScript frameworks used to build dynamic and interactive user interfaces for web applications.',
        image: image5,
        link: '',
        icon: icon5
    },
    {
        id: 6,
        title: 'Erp Software',
        description: 'ERP software centralizes and automates business processes, enhancing efficiency and decision-making with real-time visibility across departments.',
        image: image6,
        link: '',
        icon: icon1
    },
    {
        id: 7,
        title: 'ESPARSE HRMS Software',
        description: 'HRMS (Human Resource Management System) software streamlines HR processes like recruitment, payroll, and performance management.',
        image: image7,
        link: '',
        icon: icon2
    },
    {
        id: 8,
        title: 'ESPARSE CRM Software',
        description: 'CRM (Customer Relationship Management) software centralizes customer data and automates sales, marketing, and support processes.',
        image: image8,
        link: '',
        icon: icon4
    },
    {
        id: 9,
        title: 'UI/UX Design',
        description: 'UI/UX design combines user interface (UI) aesthetics with user experience (UX) functionality to create intuitive and visually appealing digital experiences.',
        image: image9,
        link: '',
        icon: icon6
    },
    {
        id: 10,
        title: ' BACKEND: - NODEJS',
        description: 'Node.js is a server-side runtime environment that allows developers to build scalable and efficient backend applications using JavaScript.',
        image: image10,
        link: '',
        icon: icon7
    }
]

const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "100px",
    slidesToShow: 3,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    focusOnSelect: true,
};


const HomeServices = () => {


    return (
        <div className='bg-[#EAEEFB] h-[633px] px-[88px] flex items-center justify-center gap-[29px] relative'>

            <Slider {...settings} className="overflow-hidden w-full">
                {services.map((s) => (
                    <div className='w-[363px] h-[560px] relative flex items-center justify-center' key={s.id}>
                        <img alt='sde' src={s.image} className='w-[363px] h-[358.75px]' />
                        <div className='w-[317px] h-[310px] bg-[#FFF] rounded flex flex-col items-center p-6 gap-4 absolute bottom-5 left-6'>
                            <div className='w-[84px] h-[84px] p-[20px] rounded-[79.48px] shadow-[0_2px_2px_0px_rgba(0,0,0,0.25)] bg-[#FFFFFF] absolute -top-10'>
                                <img src={s.icon} alt='icon1' className=' '
                                />
                            </div>
                            <div className='text-center mt-10'>
                                <p className='text-[16px] font-bold leading-[25.6px]'>
                                    {s.title}
                                </p>
                                <p className='text-[16px] font-normal leading-[25.6px] text-left px-3 '>
                                    {s.description}
                                </p>
                            </div>
                            <button className='text-[16px] rounded absolute bottom-2 px-6 py-2 bg-[#006BD3] text-[#fff]'>
                                View Detail
                            </button>
                        </div>
                    </div>


                ))}
            </Slider>
        </div >

    )
}

export default HomeServices
