import React from 'react'
import bgImage from '../../assets/servicesImages/csds/hero.png'
import bgImage2 from '../../assets/servicesImages/csds/hero2.png'
import Slider from 'react-slick'
import icona from '../../assets/servicesImages/csds/a.png'
import iconb from '../../assets/servicesImages/csds/b.png'
import iconc from '../../assets/servicesImages/csds/c.png'
import icond from '../../assets/servicesImages/csds/d.png'
import icone from '../../assets/servicesImages/csds/e.png'
import iconf from '../../assets/servicesImages/csds/f.png'



const items = [
    {
        icon: icona,
        title: 'Application Development',
        desc: 'We understand that every business has a different software requirement and we cater them with a great variety of Software Development Services. We have delivered Some of the core services are mentioned below.    '
    },
    {
        icon: iconb,
        title: 'Product Development & Maintenance ',
        desc: "Developing tailor-made products that are beyond expectations is our forte. Covering multiple industries over the years, we are capable enough to tailor software products that help better match business needs including end-user demand.    "
    },
    {
        icon: iconc,
        title: 'Dedicated Development Center ',
        desc: "Sparse School/College ERP modules are adaptable and customizable to your institution's specific needs, making it a dynamic solution. "
    },
    {
        icon: icond,
        title: 'Migration & Upgradation',
        desc: "Our tech engineers are always available to assist you if you need to move your current software to a different platform or update it to the new version.  "
    },
    {
        icon: icone,
        title: 'Testing and QA',
        desc: "Our QA & Testing service reduces the time and effort in testing so that you can exceed beyond your end user's expectations. Businesses across the globe tend to hire our customized services and have a positive edge of achieving the best level of quality.      "
    },
    {
        icon: iconf,
        title: 'Enterprise Solutions',
        desc: 'Through a team of excellence focused  staff, we endeavor hard to turn your concept into a reality. Get in touch to get assistance at each and every stage of the software development lifecycle.'
    }
]


const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 3.5,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
};

const CSDS = () => {
    return (
        <div className='flex flex-col gap-[64px]'>

            <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
                style={{ backgroundImage: `url(${bgImage})` }}>
                <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)]  z-0"></div>
                <div className='flex flex-col gap-[48px] p-28 z-10'>
                    <div className='flex flex-col w-[600px] h-[270px] gap-[32px]'>
                        <p className='font-nunito text-[48px] leading-[67.2px] text-left'>
                            CUSTOM SOFTWARE DEVELOPMENT SERVICES
                        </p>
                        <p className="text-[18px] w-[550px] font-normal leading-[25.6px] text-left">
                            Our specialty is custom software product creation, and we've completed a vast range of projects with clients all over the world.                             </p>
                    </div>
                </div>
            </div>


            <div className='flex flex-col gap-[36px] px-32'>
                <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>About CUSTOM SOFTWARE DEVELOPMENT SERVICES</p>
                <p className='font-nunito text-[16px] font-medium leading-[25.6px] text-justify'>
                    Due to our extensive expertise, team of trained experts, core industry knowledge, and committed operating procedure, we are a leading custom software development firm that provides top-rated custom software development services. We recognise that every business has unique software specifications, and we provide a wide range of software development services to meet those needs.
                </p>
            </div>

            <div className='flex flex-col gap-[36px]'>
                <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Why Us?</p>
                <div>
                    <img src={bgImage2} alt='bgImage2' />
                </div>
            </div>

            <div className='flex flex-col gap-[36px] px-48'>
                <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>We Provide</p>
                <p className='font-nunito text-[16px] font-medium leading-[25.6px] text-center'>
                    We recognise that every company has unique software requirements, and we provide a wide range of CUSTOM SOFTWARE DEVELOPMENT SERVICES to
                    meet those needs. We had services. Some of the most important are mentioned below.                </p>
            </div>


            <div className='flex flex-col justify-center mb-20'>
                <div className='flex flex-col gap-[32px]'>
                    <Slider {...settings} className="overflow-hidden w-full px-10 my-3">
                   

                        {items.map((item) => (

                            <div className='py-5'>


                                <div className='w-[350px] h-[489px] py-[24px] px-[1px] flex flex-col items-center justify-center bg-[white] gap-[8px] rounded-lg shadow-[1px_1px_2px_2px_#00000040]'>


                                    <div className='w-[277px] h-[352.57px] flex flex-col items-center gap-[38px] px-6'>
                                        <img src={item.icon} alt='icon1' className='w-[60.33px] h-[59.49px]' />


                                        <p className='text-[#3C4858] text-center font-[900] font-[20px] px-5'>
                                            {item.title}
                                        </p>
                                        <p className='font-[#161C2D] text-center font-[16px] leading-[24px]'>
                                            {item.desc}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>

        </div>
    )
}

export default CSDS