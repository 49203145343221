import React from 'react'
import icon1 from '../assets/servicesImages/webdev/icon1.png'
import icon2 from '../assets/servicesImages/webdev/icon2.png'
import icon3 from '../assets/servicesImages/webdev/icon3.png'
import icon4 from '../assets/servicesImages/webdev/icon4.png'
import icon5 from '../assets/servicesImages/webdev/icon5.png'
import icon6 from '../assets/servicesImages/webdev/icon6.png'
import Slider from 'react-slick'



const items = [
    {
        icon: icon1,
        title: 'Responsive Website Design',
        desc: 'Static website designing services provided by the Nextwebi, it’s a website which does not have database good for small businesses.'
    },
    {
        icon: icon2,
        title: 'Static Website Design',
        desc: 'Static website designing services provided by the which does not have database good for small businesses.'
    },
    {
        icon: icon3,
        title: 'Dynamic Website Design',
        desc: 'Static website designing services provided by the Nextwebi, it’s a website which does not have database good for small businesses.'
    },
    {
        icon: icon4,
        title: 'CMS',
        desc: 'Static website designing services provided by the Nextwebi, it’s a website which does not have database good for small businesses.'
    },
    {
        icon: icon5,
        title: 'API Integration',
        desc: 'Static website designing services provided by the which does not have database good for small businesses..'
    },
    {
        icon: icon6,
        title: 'E - Commerce',
        desc: 'Static website designing services provided by the Nextwebi, it’s a website which does not have database good for small businesses.'
    },
]


const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 3.5,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
};

const WebDevsection4 = () => {
    return (
        <div className='bg-[#EAEEFB] h-[540px] flex flex-col justify-center mb-20'>
            <div className='flex flex-col gap-[32px]'>
                <p className='text-[32px] font-semibold leading-[23.4px] text-center'>
                    Our Features
                </p>

                <Slider {...settings} className="overflow-hidden w-full px-10 my-3">

                    {items.map((item) => (
                        <div className=''>
                            <div className='w-[362px] h-[370px] px-[15px] flex flex-col items-center justify-center bg-[white] gap-[28px] shadow-[2px_#515A9340] rounded-lg'>

                                <div className='w-[237px] flex justify-center'>
                                    <img src={item.icon} alt='icon1' className='w-[60.33px] h-[59.49px]' />

                                </div>
                                <p className='text-[#3C4858] font-[900] font-[20px]'>
                                    {item.title}
                                </p>
                                <p className='font-[#161C2D] text-left font-[16px] leading-[24px] px-3'>
                                    {item.desc}
                                </p>
                            </div>
                            
                        </div>

                    ))}
                </Slider>
            </div>

        </div>
    )
}

export default WebDevsection4