import React from 'react'
import bgImage from '../assets/blog/hero.png'
import card from '../assets/blog/card.png'
import { MdKeyboardArrowRight } from "react-icons/md";


const cards = new Array(18).fill(
    {
        image: card,
        title: 'How Might A Mobile App Help Your Non-Profit Company?',
        desc: "Initially, the purpose of mobile apps was to promote productivity by collecting contacts, scheduling emails, and managing calendars.by collecting contacts, scheduling emails, and managing calendars. inspired to produce apps for a variety of niches, including games, entertainment, social networks, and n...",
        link: '/read'

    }
)
const Blog = () => {
    return (
        <div className='flex flex-col gap-[64px]'>

            <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
                style={{ backgroundImage: `url(${bgImage})` }}>
                <div className="absolute inset-0 bg-gradient-to-r from-[#171f81]/[.33] to-[#c5cfeff0]/[.18] z-0"></div>
                <div className='flex flex-col gap-[48px] p-28 z-10'>
                    <div className='flex flex-col w-[415px]'>
                        <p className='font-nunito text-[48px] leading-[67.2px] text-left'>
                            BLOGs
                        </p>
                    </div>
                </div>
            </div>

            <p className='text-center text-[32px] font-semibold leading-[23.4px]'>BLOGs</p>

            <div className='flex flex-wrap mb-10 gap-5 w-[100%] justify-center'>

                {cards.map((c, index) => (
                    <div className='w-[400px] h-[548px] shadow-[0px_4px_4px_0px_#00000040]' key={index}>
                        <img src={c.image} className='h-[195px]' alt='card' />
                        <div className='flex flex-col p-4 gap-[24px]'>

                            <p className='text-[20px] font-bold text-left text-[#3C4858]'>{c.title}</p>
                            <p className='text-[16px] text-left text-[#3C4858]'>{c.desc}</p>
                            <div className='text-[#2F55D4] text-[16px] flex items-center gap-1'>
                                <a href={c.link}>Read More</a>
                                <MdKeyboardArrowRight className='text-[24px]' />
                            </div>
                        </div>
                    </div>
                ))}

            </div>

        </div>
    )
}

export default Blog