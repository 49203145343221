import React from 'react'
import r1 from '../assets/readmore/image1.png'
import r2 from '../assets/readmore/image2.png'
import r3 from '../assets/readmore/image3.png'
import r4 from '../assets/readmore/image4.png'

const ReadmoreHome = () => {
    return (
        <div className='h-[527px] p-[33px_89px] flex flex-col gap-10 items-center'>
            <p className='text-[32px] font-semibold leading-[42px] text-center'>Esparse Matrix Solutions News & Events</p>


            <div className='w-[1079px] h-[381px] flex gap-[30px]'>



                <div className='w-[247px] h-[373px] rounded-lg shadow-[0px_0px_3px_0px_#3C485826]'>
                    <img src={r1} alt='r1' className='object-cover rounded-t-lg' />
                    <div className='w-[247px] h-[197px] p-[24px] flex flex-col gap-[12px]'>
                        <p className='text-[16px]  h-[87px] leading-[30px] text-left'>
                            How Might A Mobile App Help Your Non- Profit Company?
                        </p>
                        <button className='w-full font-medium p-[8px_12px] rounded border-2 border-[#000]'>
                            Read more...
                        </button>
                    </div>
                </div>


                <div className='w-[247px] h-[373px] rounded-lg shadow-[0px_0px_3px_0px_#3C485826]'>
                    <img src={r2} alt='r1' className='object-cover rounded-t-lg' />
                    <div className='w-[247px] h-[197px] p-[24px] flex flex-col gap-[12px]'>
                        <p className='text-[16px]  h-[87px] leading-[30px] text-left'>
                            The importance of mobile apps for educational institutions                            </p>
                        <button className='w-full font-medium p-[8px_12px] rounded border-2 border-[#000]'>
                            Read more...
                        </button>
                    </div>
                </div>



                <div className='w-[247px] h-[373px] rounded-lg shadow-[0px_0px_3px_0px_#3C485826]'>
                    <img src={r3} alt='r1' className='object-cover rounded-t-lg' />
                    <div className='w-[247px] h-[197px] p-[24px] flex flex-col gap-[12px]'>
                        <p className='text-[16px]  h-[87px] leading-[30px] text-left'>
                            The Ultimate Tutorial:
                            Developing Your Own
                            Educational App in 2023
                        </p>
                        <button className='w-full font-medium p-[8px_12px] rounded border-2 border-[#000]'>
                            Read more...
                        </button>
                    </div>
                </div>


                <div className='w-[247px] h-[373px] rounded-lg shadow-[0px_0px_3px_0px_#3C485826]'>
                    <img src={r4} alt='r1' className='object-cover rounded-t-lg' />
                    <div className='w-[247px] h-[197px] p-[24px] flex flex-col gap-[12px]'>
                        <p className='text-[16px] h-[87px] leading-[30px] text-left'>
                            Trends in UI/UX Design for
                            2023
                        </p>
                        <button className='w-full font-medium p-[8px_12px] rounded border-2 border-[#000]'>
                            Read more...
                        </button>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default ReadmoreHome