import React from 'react'
import bgImage from '../../assets/servicesImages/hrms/hero.png'
import card1 from '../../assets/servicesImages/hrms/1.png'
import card2 from '../../assets/servicesImages/hrms/2.png'
import card3 from '../../assets/servicesImages/hrms/3.png'
import card4 from '../../assets/servicesImages/hrms/4.png'
import card5 from '../../assets/servicesImages/hrms/5.png'
import card6 from '../../assets/servicesImages/hrms/6.png'
import icon1 from '../../assets/servicesImages/hrms/icon1.png'
import icon2 from '../../assets/servicesImages/hrms/icon2.png'
import icon3 from '../../assets/servicesImages/hrms/icon3.png'
import icon4 from '../../assets/servicesImages/hrms/icon4.png'

import Slider from 'react-slick'




const cards = [
    {
        image: card1,
        title: 'RECRUITMENT',
        desc: 'HR may receive input from different agencies on employee preparation, yearly  and ad - hoc personnel needs. The HRMS assists management workers with handling resume databases, credentials, and interacts with career portals.'
    },
    {
        image: card2,
        title: 'PERFORMANCE MANAGEMENT',
        desc: "Esparse HRMS aids in the development of employee evaluation records on a regular basis. Any employee's efficiency parameters can be identified using HRMS. Promotions, preparation, and right-sizing will all benefit from the data."
    },
    {
        image: card3,
        title: 'EMPLOYEE SELF SERVICES',
        desc: 'Employee self-services such as copying pay stubs, arranging for tax savings and investments, scheduling trips, internal communications, and outdoor activities can all be efficiently managed with adjustable schedules.'
    },
    {
        image: card4,
        title: 'LEAVE MANAGEMENT',
        desc: 'Esparse HRMS allows you to handle salaries, leave, leave sanctions, attendance, and regularity, as well as integrate with biometric systems. HRMS guarantees that payroll work at the end of the month is stress-free.'
    },
    {
        image: card5,
        title: 'TRAINING',
        desc: 'Training requirements are entered into HRMS and received by HR, which processes the data and schedules skill- based training. Competency maps created with HRMS can be used to monitor the success of training. '
    },
    {
        image: card6,
        title: 'HELP DESK',
        desc: 'Esparse HRMS Help Desk is a point of touch for staff to speak with managers about concerns and challenges, as well as requests for materials and other products. It has the ability to leave a written note as well as a number of requisition types.'
    }

]


const card = [
    {
        icon: icon1,
        title: 'Cost Effective',
        desc: 'As a result of the reduced paper work, there is a higher yield per working hour. Per mission, the number of workers has been reduced. Monitoring in real time leads to a more effective Method.    '
    },
    {
        icon: icon2,
        title: 'Easy To Customization ',
        desc: 'Client feedback, market, organisational culture, and other criteria were used to build this. Flexible to adapt to the needs of the company as it expands.'
    },
    {
        icon: icon3,
        title: 'Unbiased',
        desc: 'Making managerial decisions becomes easy. Performance metrics are fed into the system and can be tracked in real time for feedback.   '
    },
    {
        icon: icon4,
        title: 'Structured SYSTEM',
        desc: 'Easily structured for Data analytics for board sessions, and evaluation  meetings, among other things. Within minutes of performing calculation  '
    },
]



const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 4,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
};


const HRMS = () => {
    return (
        <div className='flex flex-col gap-[64px]'>

            <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
                style={{ backgroundImage: `url(${bgImage})` }}>
                <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)]  z-0"></div>
                <div className='flex flex-col gap-[48px] p-28 z-10'>
                    <div className='flex flex-col w-[470px] h-[270px] gap-[32px]'>
                        <p className='font-nunito text-[48px] leading-[67.2px] text-left'>
                            ESPARSE HRMS SOLUTIONS
                        </p>
                        <p className="text-[18px] w-[500px] font-normal leading-[25.6px] text-left">
                            A modern organization's most critical and cost-intensive role is human resource management.
                        </p>
                    </div>
                </div>
            </div>


            <div className='flex flex-col gap-[36px] px-52'>
                <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>About eSparse HRMS Solutions</p>
                <p className='font-nunito text-[16px] font-medium leading-[25.6px] text-justify'>A modern organization's most critical and cost-intensive role is human resource management. The HR department is often burdened with cumbersome documentation and monotonous duties due to its various roles, which include recruiting, resource control, and employee grievance redressal. Esparse HRMS Solutions has developed a formidable reputation for delivering customised HRMS solutions. Esparse HRMS is a one-stop shop for all HR needs, including recruiting, employee database management, leave tracking, payroll management, preparation, and success management. Since each client is different in terms of team size, processes, and specifications, each HRMS Module is customised to meet the specific needs of the client customers. </p>
            </div>


            <div className='flex flex-col gap-[36px] px-36'>
                <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Esparse HRMS's Solutions Key Features</p>
                <p className='font-nunito text-[16px] font-medium leading-[25.6px] text-center'>Human resources are unique and provide a significant competitive edge to every business. In today's demanding climate, attracting and keeping the best workforce has been the most difficult task. The Esparse HRMS allows smooth incorporation between departmental, intra-departmental, and entity levels.</p>
            </div>




            <div className=' h-[500px] flex flex-col justify-center'>
                <div className='flex flex-col gap-[29px]'>
                    <Slider {...settings} className="overflow-hidden w-full px-10 my-3">

                        {cards.map((item) => (
                            <div className='py-10 '>

                                <div className='w-[292px] h-[490px] rounded-lg shadow-[1px_4px_4px_1px_#00000036]'>
                                    <img src={item.image} alt='card' className='h-[200px] w-full rounded-tl-lg rounded-tr-lg' />
                                    <div className='text-[#3C4858] mt-1'>
                                        <p className='text-[20px] font-extrabold leading-[30px] tracking-[0.5px] text-center h-[46px]'>{item.title}</p>
                                        <p className='text-[14px] font-normal leading-[25.6px] text-center px-5'>{item.desc}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>


            <div className='flex flex-col gap-[36px] px-56'>
                <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Why ESPARSE HRMS?</p>
                <p className='font-nunito text-[16px] font-medium leading-[25.6px] text-center'>Esparse HRMS is useful because it allows for more operating hours a day. The presented personalised HRMS approach eliminates the system's flaws.
                    See how HRMS benefits our customers.</p>
            </div>




            <div className='flex justify-center h-[400px] px-24 items-center gap-[43px]'>
                {card.map((c, index) => (
                    <div key={index} className=''>
                        <div className='w-[267px] h-[300px] p-[20px_10px_20px_10px] shadow-[1px_1px_8px_2px_#515A9340] flex flex-col items-center gap-[10px]'>

                            <div className='w-[237px] flex justify-center'>
                                <img src={c.icon} alt='icon1' className='w-[60.33px] h-[59.49px]' />

                            </div>
                            <p className='text-[#3C4858] text-center font-[900] font-[20px]'>
                                {c.title}
                            </p>
                            <p className='font-[#161C2D] text-center font-[16px] leading-[24px] px-5'>
                                {c.desc}
                            </p>
                        </div>

                    </div>
                ))}
            </div>

        </div>
    )
}

export default HRMS