import React from 'react'
import bgImage from '../../assets/servicesImages/swt/hero.png'
import swt from '../../assets/servicesImages/swt/swt.png'
import pic1 from '../../assets/servicesImages/swt/1.png'
import pic2 from '../../assets/servicesImages/swt/2.png'
import pic3 from '../../assets/servicesImages/swt/3.png'
import pic4 from '../../assets/servicesImages/swt/4.png'
import a1 from '../../assets/servicesImages/swt/1a.png'
import b1 from '../../assets/servicesImages/swt/1b.png'
import c1 from '../../assets/servicesImages/swt/1c.png'
import d1 from '../../assets/servicesImages/swt/1d.png'
import i1 from '../../assets/servicesImages/swt/i1.png'
import i2 from '../../assets/servicesImages/swt/i2.png'
import i3 from '../../assets/servicesImages/swt/i3.png'

const item = [
    {
        icon: i1,
        title: 'Test scenario',
        desc: 'Test case scenarios will be listed in a structured manner to ensure end-to-end performance quality is assessed.'
    },
    {
        icon: i2,
        title: 'Phase testing',
        desc: 'The application is tested from various potential perspectives and user scenarios to identify and resolve possible quality issues.'
    },
    {
        icon: i3,
        title: 'Report',
        desc: 'A structured report is shared with developers to resolve the failed test scenarios and a retest is conducted to ensure that each issue is addressed effectively.'
    },
]
const cards = [
    {
        image: pic1,
        tile: 'Functional testing',
        desc: 'Performance testing determines the speed, responsiveness, and stability under different test case scenarios and workloads. With our rich experience resolves these issues through application behavior analysis and quality checks.',
    },
    {
        image: pic2,
        tile: 'Performance testing',
        desc: 'Performance testing determines the speed, responsiveness, and stability under different test case scenarios and workloads. With our rich experience resolves these issues through application behavior analysis and quality checks.',
    },
    {
        image: pic3,

        tile: 'Exploratory testing',
        desc: "Based of the user stories we write test cases and our QA and testing team conducts repetitive tests as a part of exploratory testing. The comprehensive test coverage strategy reviews the application from the user's perspective.",
    },
    {
        image: pic4,
        tile: 'Security testing',
        desc: "We identify potential vulnerabilities and threats through rigorous information security test scenarios. Risk assessment and penetration testing are done before and after release to defend your application from malicious threats which would hinder the application's function.",

    }
]

const card = [
    {
        image: a1,
        tile: 'Comprehensive quality assessment',
        desc: 'Code quality and functioning capability is tested in a detailed manner to ensure the application fulfils its explicit and implicit requirements.',
    },
    {
        image: b1,
        tile: 'Quality, security, transparency',
        desc: 'Delivering quality assured, and secured applications in a strategic and transparent way with accurate and consistent test results.',
    },
    {
        image: c1,

        tile: 'Accelerated delivery',
        desc: "On-time delivery within the stipulated while simultaneously maintaining the code quality with the help of advanced testing tools and technologies.",
    },
    {
        image: d1,
        tile: 'Maximum coverage',
        desc: "Developers ensure that the application is tested aggressively ensuring its seamless compatibility and functioning across devices and users.",

    }
]

const SWservices = () => {
    return (
        <div className='flex flex-col gap-[64px]'>

            <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
                style={{ backgroundImage: `url(${bgImage})` }}>
                <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)]  z-0"></div>
                <div className='flex flex-col gap-[48px] p-28 z-10'>
                    <div className='flex flex-col  gap-[32px]'>
                        <p className='w-[600px] text-[48px] leading-[67.2px] text-left'>
                            Software Testing Services
                        </p>
                        <p className="text-[18px] w-[500px] font-normal leading-[25.6px] text-left">
                            Ensuring Quality and Reliability Through Comprehensive Software Testing Services                                                </p>
                    </div>
                </div>
            </div>


            <div className='flex flex-col gap-[36px] px-28'>
                <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Software Testing Services</p>
                <p className='text-[16px] leading-[25.6px] text-justify'>
                    Reliable engineering-led software testing services in Bangalore, India by Esparse Matrix Solutions to help your websites and applications function seamlessly, maximize performance and enhance scalability.                </p>
            </div>

            <div className='flex flex-col gap-[36px]'>
                <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Professional software testing services @ Esparse Matrix Solutions</p>
                <div className='flex  justify-center items-center gap-10  h-[429px] mt-10 px-28'>
                    <p className='text-[20px] w-[643.12px] px-8'>
                        At Esparse Matrix Solutions, our expert testers deliver quality-driven software testing to eliminate errors, reduce bugs, and enhance user experiences. We adhere to digital quality assurance standards, providing scalable, robust, and reliable solutions that prevent workflow disruptions.

                        <br />
                        <br />
                        <br />
                        Our comprehensive services include risk analysis, test planning, execution, and defect reporting to ensure operational efficiency and minimal production defects. By employing advanced testing methodologies, we help your business achieve consistent, high-performing applications that offer seamless user experiences.
                    </p>
                    <img src={swt} alt='ml' className='w-[450px]' />

                </div>
            </div>
            <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Software testing and QA solutions</p>


            <div className='flex justify-center gap-10'>

                {cards.map((item) => (
                    <div className='w-[292px] h-[447px] rounded-lg shadow-[0px_4px_4px_0px_#00000040]' >
                        <img src={item.image} alt='icon1' className='mb-3 rounded-tr-lg rounded-tl-lg' />
                        <div className='flex flex-col  gap-[12px] px-5'>
                            <p className='text-[16px] px-8 font-semibold text-center'>
                                {item.tile}
                            </p>
                            <p className="text-[16px] font-normal text-left">
                                {item.desc}
                            </p>
                        </div>
                    </div>
                ))}

            </div>

            <div className='flex flex-col gap-[36px] px-28'>
                <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Key differentiator</p>
                <p className='text-[16px] leading-[25.6px] text-justify'>
                    Our QA and software testing services expedite your testing process by implementing various test case scenarios to detect, analyze and resolve potential quality issues which might hinder the productivity and performance of the application.
                </p>
            </div>

            <div className='flex justify-center gap-10'>

                {card.map((item) => (
                    <div className='w-[292px] h-[447px] rounded-lg shadow-[0px_4px_4px_0px_#00000040]' >
                        <img src={item.image} alt='icon1' className='mb-3 rounded-tr-lg rounded-tl-lg' />
                        <div className='flex flex-col  gap-[12px] px-5'>
                            <p className='text-[16px] px-8 font-semibold text-center'>
                                {item.tile}
                            </p>
                            <p className="text-[16px] font-normal text-left">
                                {item.desc}
                            </p>
                        </div>
                    </div>
                ))}

            </div>

            <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Software testing and QA solutions</p>
            <div className='flex justify-center gap-10 mb-10'>

                {item.map((item) => (
                    <div>

                        <div className='w-[362px] h-[377px] p-[24px_18px_24px_18px_] flex flex-col items-center justify-center bg-[white] gap-[8px] border-2 rounded-lg'>

                            <div className='w-[237px] flex justify-center'>
                                <img src={item.icon} alt='icon1' className='w-[60.33px] h-[59.49px] ' />

                            </div>
                            <p className='text-[#3C4858] font-[900] font-[20px]'>
                                {item.title}
                            </p>
                            <p className='font-[#161C2D] font-[16px] leading-[25.6px] text-center px-3'>
                                {item.desc}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SWservices