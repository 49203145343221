import React from 'react'
import heroImage from '../assets/heroImage.png'
import bgImage from '../assets/heroBg.png'
const Hero = () => {
    return (
        <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
            style={{ backgroundImage: `url(${bgImage})` }}>
            <div className="absolute inset-0 bg-[rgba(44,54,88,0.65)] z-0"></div>
            <div className='flex flex-col gap-[48px] p-28 z-10'>
                <div className='flex flex-col items-center w-[550px] h-[270px] gap-[32px]'>
                    <div>
                        <p className='font-nunito text-[48px] leading-[67.2px] text-left'>
                            Digitally Yours.
                        </p>
                        <p className='font-nunito text-[48px] leading-[67.2px] text-left'>
                            We are a Digital Agency.
                        </p>
                    </div>
                    <div>
                        <p className="font-nunito text-[18px] font-normal leading-[25.6px] text-left">
                            eSparse Matrix Solutions Private Limited is a Best Software Development Company that includes Enterprise Software development, Website designing and development, Mobile application development, Digital marketing.
                        </p>
                    </div>
                </div>
                <div>
                    <button className="bg-[#006BD3] px-[34px] py-4   rounded gap-[8px]">Get Started</button>
                </div>

            </div>
            <div className='absolute bottom-0 right-0 w-1/2 h-auto'>
                <img alt='heroImage' src={heroImage} className='w-[641.48px] h-[554px]' />
            </div>

        </div>
    )
}

export default Hero