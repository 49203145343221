import React from 'react'
import AboutSection1 from '../Pages/AboutSection1'
import AboutSection2 from '../Pages/AboutSection2'
import WhyChooseUs from '../Pages/WhyChooseUs'
import AboutSection4 from '../Pages/AboutSection4'
import AboutSection5 from '../Pages/AboutSection5'
import ReadmoreHome from '../Pages/ReadmoreHome'
import AboutSection7 from '../Pages/AboutSection7'

const About = () => {
    return (

        <div className='flex flex-col gap-[64px]'>
            <AboutSection1 />
            <AboutSection2 />
            <WhyChooseUs />
            <AboutSection4 />
            <AboutSection5 />
            <ReadmoreHome />
            <AboutSection7 />
        </div>
    )
}

export default About